import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { downloadExportPageFile } from '../ediscoveryActions';

const ExportTable = ({ columns, rows }) => {
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);

  useEffect(() => {
    let data = rows.reverse();
    setRowsData(data);
  }, [rows]);
  const downloadData = (id) => {
    dispatch(downloadExportPageFile(id));
  };
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell sx={{ pl: 0 }} key={i}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData.length
              ? rowsData.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ p: 0 }}>
                      <Button>
                        <FileDownloadIcon onClick={() => downloadData(i + 1)} />
                      </Button>
                    </TableCell>
                    <TableCell sx={{ p: 0 }}>{row.noOfFiles}</TableCell>
                    <TableCell sx={{ p: 0 }}>{moment(row.startTime).format('YYYY-MM-DD HH:mm:ss.SSS')}</TableCell>
                    <TableCell sx={{ p: 0 }}>{moment(row.endTime).format('YYYY-MM-DD HH:mm:ss.SSS')}</TableCell>
                    <TableCell sx={{ p: 0 }}>0.{Math.abs(row.startTime - row.endTime)} second(s)</TableCell>
                    <TableCell sx={{ p: 0 }}>{row.statusMessage}</TableCell>
                    <TableCell sx={{ p: 0 }}>{row.status}</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ExportTable;
