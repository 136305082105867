import {
    Button,
    Card,
    Container,
    Modal,
    Stack,
    Typography,
  } from '@mui/material';  
  import Page from 'src/components/Page';
  import useSettings from 'src/hooks/useSettings';
  
  const SuccessModal = ({ openSuccess, close }) => {
  const { themeStretch } = useSettings();
    return (
      <>
        <Modal
          open={openSuccess}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Page sx={{ width: '60%' }}>
            <Container maxWidth={themeStretch ? false : 'xl'}>
              <Card sx={{ p: '40px' }}>
                <Stack direction='column'>
                <Typography variant="h5">Restore File</Typography>
                <Typography sx={{pt:1,pb:1}} variant="p">Max export limit is 500 documents, your export is in progress, you may Download OR Check Progress by going to the 'Export' tab</Typography>
                <Button variant='contained' sx={{width:"80px"}} onClick={()=>close()}>Close</Button>
                </Stack>
              </Card>
            </Container>
          </Page>
        </Modal>
      </>
    );
  };
  
  export default SuccessModal;
  