import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import { getShares, testAccess } from '../jobManagementActions';
import FileSystemTable from './FileSystemTable';
import SharesModal from './SharesModal';
import TestAccessModal from './TestAccessModal';
import { isEmpty } from 'lodash';

const FileSystem = ({ uncPath, register, watch, errors, setValue, createFlag, remotePathDetails, ValidUncPath }) => {
  const dispatch = useDispatch();
  const shares = useSelector((state) => state.jobManagementReducer.shares);
  const testData = useSelector((state) => state.jobManagementReducer.testData);
  const defaultDetails = useSelector((state) => state.jobManagementReducer.defaultDetails);

  const [fileSystemType, setFileSystemType] = useState(watch('fileSystemType') || 'remote');
  const [uncEnable, setUncEnable] = useState(false);
  const [unc, setUnc] = useState('');
  const [localFSPath, setLocalFSPath] = useState('');
  const [remoteUnc, setRemoteUnc] = useState('');
  const [localFSPathInList, setLocalFSPathInList] = useState(watch('localFSPathsList') || []);
  const [localFSPathExList, setLocalFSPathExList] = useState(watch('localFSPathExList') || []);
  const [remotePathInList, setRemotePathInList] = useState(watch('remotePathsList') || []);
  const [remotePathExList, setRemotePathExList] = useState(watch('remoteExPathsList') || []);
  const [openSharesModal, setOpenSharesModal] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [isShowInfo, setIsShowInfo] = useState(false);
  const [remotePath, setRemotePath] = remotePathDetails
  const [notValidUncPath, setNotValidUncPath] = ValidUncPath
  const includeColumn = ['Include', 'Action'];
  const excludeColumn = ['Exclude', 'Action'];
  const [exclusionCheck, setExclusionCheck] = useState(watch('applyExclusionPolicy') || false);
  const [testAccessLoading, setTestAccessLoading] = useState(false);

  useEffect(() => {
    setValue('fileSystemType', fileSystemType);
  }, [fileSystemType]);

  useEffect(() => {
    if (createFlag) {
      setValue('remoteDomainName', defaultDetails.restoreDomainName || '');
      setValue('remoteUserName', defaultDetails.restoreUser || '');
      setValue('remoteUserPassword', defaultDetails.restorePassword || '');
    }
  }, [defaultDetails]);

  useEffect(() => {
    let path = watch('remotePathsList') || [];
    if (path.length) {
      setValue('remoteUnc', path[0]);
      setRemoteUnc(path[0]);
    }
  }, []);

  useEffect(() => {
    setValue('localFSPathsList', localFSPathInList);
    setValue('localFSPathExList', localFSPathExList);
    setValue('remotePathsList', remotePathInList);
    setValue('remoteExPathsList', remotePathExList);
  }, [localFSPathInList, localFSPathExList, remotePathInList, remotePathExList]);

  // useEffect(()=> {
  //   !isEmpty(testData) && setOpenAccessModal(true);
  // },[testData])

  useEffect(() => {
    if (remotePathInList && remotePathInList.length) {
      setRemotePath(remotePathInList)
    }
  }, [remotePathInList])

  const includePath = () => {
    if (fileSystemType === 'local') {
      let path;
      if (uncEnable) {
        path = localFSPath + '->' + unc;
      } else {
        path = localFSPath;
        let serverName = localFSPath;
        if (!(serverName.startsWith('/') || serverName.startsWith('\\') || /^[a-zA-Z]:[\\/]/.test(serverName))) {
          dispatch({
            type: ERROR_ALERT,
            payload: 'Please enter a valid path',
          });
          return;
        }
      }
      if (!checkIncludedPath(path)) {
        setLocalFSPathInList([...localFSPathInList, path]);
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: 'Path already added to Included list',
        });
      }
    } else {
      let path;
      if (uncEnable) {
        path = remoteUnc + '->' + unc;
      } else {
        path = remoteUnc;

        let serverName = remoteUnc;
        if (serverName.indexOf('\\\\') !== -1) {
          serverName = serverName.substring(2);
          if (serverName.indexOf('\\') === -1) {
            dispatch({
              type: ERROR_ALERT,
              payload: 'Please enter a valid path',
            });
            return;
          }
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: 'Please enter a valid path',
          });
          return;
        }
      }
      if (!checkIncludedPath(path)) {
        setRemotePathInList([...remotePathInList, path]);
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: 'Path already added in Included list',
        });
      }
    }
  };

  const checkIncludedPath = (path) => {
    let check = false;
    if (fileSystemType === 'local') {
      localFSPathInList.forEach((obj) => {
        if (path === obj) check = true;
      });
    } else {
      remotePathInList.forEach((obj) => {
        if (path === obj) check = true;
      });
    }
    return check;
  };

  const removeIncludedPath = (index) => {
    if (fileSystemType === 'local') {
      localFSPathInList.splice(index, 1);
      setLocalFSPathInList([...localFSPathInList]);
    } else {
      remotePathInList.splice(index, 1);
      setRemotePathInList([...remotePathInList]);
    }
  };

  const excludePath = () => {
    if (fileSystemType === 'local') {
      let path;
      if (uncEnable) {
        path = localFSPath + '->' + unc;
      } else {
        path = localFSPath;
        let serverName = localFSPath;
        if (!(serverName.startsWith('/') || serverName.startsWith('\\') || /^[a-zA-Z]:[\\/]/.test(serverName))) {
          dispatch({
            type: ERROR_ALERT,
            payload: 'Please enter a valid path',
          });
          return;
        }
      }
      if (!checkExcludedPath(path)) {
        setLocalFSPathExList([...localFSPathExList, path]);
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: 'already exist',
        });
      }
    } else {
      let path;
      if (uncEnable) {
        path = remoteUnc + '->' + unc;
      } else {
        path = remoteUnc;
        let serverName = remoteUnc;
        if (serverName.indexOf('\\\\') !== -1) {
          serverName = serverName.substring(2);
          if (serverName.indexOf('\\') === -1) {
            dispatch({
              type: ERROR_ALERT,
              payload: 'Please enter a valid path',
            });
            return;
          }
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: 'Please enter a valid path',
          });
          return;
        }
      }
      if (!checkExcludedPath(path)) {
        setRemotePathExList([...remotePathExList, path]);
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: 'Path already added in Excluded list',
        });
      }
    }
  };

  const checkExcludedPath = (path) => {
    let check = false;
    let state = 'valid';
    if (fileSystemType === 'local') {
      if (localFSPathExList.length > 0) {
        localFSPathExList.forEach((obj) => {
          if (path !== obj) {
            state = 'valid';
            check = false;
          }
          if (path === obj) {
            state = 'exist';
            check = true;
          }
        });
      } else {
        state = 'valid';
        check = false;
      }
    } else {
      if (remotePathExList.length > 0) {
        remotePathExList.forEach((obj) => {
          if (path !== obj) {
            state = 'valid';
            check = false;
          } else if (path === obj) {
            state = 'exist';
            check = true;
          }
        });
      } else {
        state = 'valid';
        check = false;
      }
    }
    return check;
  };

  const removeExcludedPath = (index) => {
    if (fileSystemType === 'local') {
      localFSPathExList.splice(index, 1);
      setLocalFSPathExList([...localFSPathExList]);
    } else {
      remotePathExList.splice(index, 1);
      setRemotePathExList([...remotePathExList]);
    }
  };

  const getSharesData = () => {
    let serverName = remoteUnc;
    let finalServerName = serverName;
    if (serverName.indexOf('\\\\') != -1) {
      serverName = serverName.substring(2);
      finalServerName = serverName;
      if (serverName.indexOf('\\') != -1) {
        serverName = serverName.substring(0, serverName.indexOf('\\'));
        finalServerName = serverName;
      }
    }
    dispatch(
      getShares(
        { domain: watch('remoteDomainName'), userName: watch('remoteUserName'), password: watch('remoteUserPassword') },
        finalServerName
      )
    );
    setOpenSharesModal(true);
  };

  const getAccessData = async () => {
    setTestAccessLoading(true)
    let serverName = remoteUnc;
    if (serverName.indexOf('\\\\') != -1) {
      serverName = serverName.substring(2);
      if (serverName.indexOf('\\') == -1) {
        dispatch({
          type: ERROR_ALERT,
          payload: 'Please enter a valid path',
        });
        return;
      }
    }
    await dispatch(
      testAccess({
        domain: watch('remoteDomainName'),
        userName: watch('remoteUserName'),
        password: watch('remoteUserPassword'),
        agentId: watch('agentId') || 0,
        uncPath: remoteUnc,
        libType: watch('remoteLibType') || 'JNQ',
      })
    );
    setOpenAccessModal(true);
    setTestAccessLoading(false);
  };

  return (
    <Card sx={{ m: '20px 80px 40px 80px', p: '20px' }}>
      <SharesModal
        shares={shares}
        openModal={openSharesModal}
        close={() => setOpenSharesModal(false)}
        watch={watch}
        pathList={[remotePathInList, setRemotePathInList]}
      />
      <TestAccessModal
        testData={testData}
        openModal={openAccessModal}
        close={() => { setOpenAccessModal(false) }}
        watch={watch}
        loader={testAccessLoading}
      />
      <Grid container spacing={1} sx={{ mx: '10px' }}>
        <Grid item xs={12} md={7}>
          <FormControl
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <RadioGroup
              aria-labelledby="sourceId"
              name="fileSystemType"
              sx={{ display: 'flex', flexDirection: 'row' }}
              value={fileSystemType}
              {...register('fileSystemType')}
              onChange={(e) => {
                setValue('fileSystemType', e.target.value);
                setFileSystemType(e.target.value);
              }}
            >
              <FormControlLabel value="local" control={<Radio />} label="Absolute Path e.g. D:/share" />
              <FormControlLabel value="remote" control={<Radio />} label="CIFS(SMB Protocol)" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <FormControlLabel
              name="checkDeDuplication"
              control={<Checkbox />}
              label="Enable support for Windows Deduplication"
              {...register('checkDeDuplication')}
            />
          </FormGroup> </Grid>
        {fileSystemType != 'local' && (
          <Grid item xs={10} md={5}>
            <Stack direction='row'>
              <Button
                variant="contained"
                sx={{ color: '#ffff', m: '5px' }}
                onClick={getSharesData}
                disabled={
                  watch('remoteDomainName') === '' ||
                  watch('remoteUserName') === '' ||
                  watch('remoteUserPassword') === '' ||
                  remoteUnc === ''
                }
              >
                Get Shares
              </Button>
              <Button
                variant="contained"
                sx={{ color: '#ffff', m: '5px' }}
                onClick={getAccessData}
                disabled={
                  watch('remoteDomainName') === '' ||
                  watch('remoteUserName') === '' ||
                  watch('remoteUserPassword') === '' ||
                  remoteUnc === ''
                }
              >
                Test CIFS Access
              </Button>

              <IconButton onClick={() => setIsShowInfo(!isShowInfo)}>
                <InfoIcon />
              </IconButton>
            </Stack>

            {isShowInfo && (
              <Typography variant="caption" color="red" sx={{ width: 'fit-content' }}>
                Only applicable for an on-prem deployment
              </Typography>
            )}
          </Grid>
        )}

        {fileSystemType === 'local' && (
          <Grid item xs={12} md={11}>
            <TextField
              label="Folder Path"
              variant="standard"
              sx={{ width: '100%' }}
              onChange={(e) => setLocalFSPath(e.target.value)}
              required
            />
          </Grid>
        )}
        {fileSystemType === 'remote' && (
          <>
            <Grid item xs={12} md={11}>
              <TextField
                label="Domain"
                name="remoteDomainName"
                helperText={errors.remoteDomainName && errors.remoteDomainName.message}
                error={errors.remoteDomainName && errors.remoteDomainName.message}
                {...register('remoteDomainName')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                label="Username"
                name="remoteUserName"
                variant="standard"
                helperText={errors.remoteUserName && errors.remoteUserName.message}
                error={errors.remoteUserName && errors.remoteUserName.message}
                {...register('remoteUserName')}
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="remoteUserPassword"
                type="password"
                label="Password"
                variant="standard"
                helperText={errors.remoteUserPassword && errors.remoteUserPassword.message}
                error={errors.remoteUserPassword && errors.remoteUserPassword.message}
                {...register('remoteUserPassword')}
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
              {watch('remoteUserPassword')?.includes('@') ?
                <Typography variant="caption" color="red" sx={{ width: 'fit-content' }}>
                  @ symbol in password is not accepted, please update the password and try again
                </Typography> : null
              }

            </Grid>
            <Grid item xs={12} md={7}>
              <Autocomplete
                freeSolo
                name="remoteUnc"
                value={remoteUnc || ""}
                options={uncPath && uncPath.map((option) => '\\\\' + option)}
                onChange={(e) => setRemoteUnc(e.target.textContent)}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="UNC (e.g \\server\folder)"
                    onChange={(e) => {
                      setRemoteUnc(e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={10} md={2}>
          <FormControlLabel control={<Checkbox />} onChange={() => setUncEnable(!uncEnable)} label="Map to UNC" />
        </Grid>
        {
          fileSystemType === 'remote' ?
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                sx={{ color: '#ffff', m: '5px' }}
                disabled={remoteUnc == ''}
                onClick={() => includePath()}
              >
                Include
              </Button>
              <Button
                variant="contained"
                sx={{ color: '#ffff', m: '5px' }}
                disabled={remoteUnc == ''}
                onClick={() => excludePath()}
              >
                Exclude
              </Button>
            </Grid>
            :
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                sx={{ color: '#ffff', m: '5px' }}
                disabled={localFSPath == ''}
                onClick={() => includePath()}
              >
                Include
              </Button>
              <Button
                variant="contained"
                sx={{ color: '#ffff', m: '5px' }}
                disabled={localFSPath == ''}
                onClick={() => excludePath()}
              >
                Exclude
              </Button>
            </Grid>
        }
        <Grid item xs={12} md={11}>
          {(fileSystemType == 'local' ||
            fileSystemType == 'remote') && uncEnable && (
              <TextField
                label="UNC"
                variant="standard"
                sx={{ width: '100%' }}
                value={unc}
                onChange={(e) => setUnc(e.target.value)}
              />
            )}
        </Grid>
        {!uncEnable && watch('fileSystemType') == 'remote' && <br />}
        <Grid item xs={12} md={6}>
          <FileSystemTable
            columns={includeColumn}
            rows={fileSystemType === 'local' ? localFSPathInList : remotePathInList}
            handleDelete={removeIncludedPath}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FileSystemTable
            columns={excludeColumn}
            rows={fileSystemType === 'local' ? localFSPathExList : remotePathExList}
            handleDelete={removeExcludedPath}
          />
        </Grid>
        {/* <Grid item xs={12} md={8}>
          <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <FormControlLabel
              name="processReadOnly"
              checked={!!watch('processReadOnly')}
              control={<Checkbox />}
              label="Read Only"
              {...register('processReadOnly')}
            />
            <FormControlLabel
              name="processArchive"
              checked={!!watch('processArchive')}
              control={<Checkbox />}
              label="Archive"
              {...register('processArchive')}
            />
            <FormControlLabel
              name="processHidden"
              checked={!!watch('processHidden')}
              control={<Checkbox />}
              label="Hidden"
              {...register('processHidden')}
            />
          </FormGroup>
        </Grid> */}
      </Grid>
    </Card>
  );
};

export default FileSystem;
