import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { addFileStore, getFileStoreInfo, updateProfile } from '../volumeActions';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from './ConfirmModal';

const FileSystem = ({ createFlag, fileStoreInfo, fileStoreType }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});
  const [selectCheck, setSelectCheck] = useState(false);
  const [nativeModeCheck, setNativeModeCheck] = useState(false);
  const schema = yup.object().shape({
    fileStoreType: yup.string().required('required'),
    fileStorePath: yup.string().required('required'),
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (params.id) {
      if (!isEmpty(fileStoreInfo)) {
        reset(fileStoreInfo);
      }
    }
  }, [fileStoreInfo]);

  useEffect(() => {
    setSelectCheck(watch('encryption'));
  }, [watch('encryption')]);

  useEffect(() => {
    setNativeModeCheck(watch('nativeMode'));
  }, [watch('nativeMode')]);

  const onSubmit = (data) => {
    let companyId = localStorage.getItem('companyId');
    data.encryption = selectCheck;
    if (params.id) {
      setOpenModal(true);
      setUpdateDetails({ ...data, paramsId: params?.id });
    } else {
      dispatch(
        updateProfile(
          {
            id: 0,
            companyId: +companyId,
            fileStoreType: data.fileStoreType,
            fileStoreName: data.fileStoreName,
            fileStorePath: data.fileStorePath || '',
            writable: true,
            fileStoreTempPath: '',
            encryption: data.encryption || false,
            nativeMode: data.nativeMode || false,
            fileStoreBase: +fileStoreType,
            fileStoreRegionName: data.fileStoreRegionName || '',
            fileStoreRegionId: data.fileStoreRegionId || 0,
            markAsDefault: data.markAsDefault || false,
            awsAuthorizedPassword: '',
            awsDefaultBasket: 0,
            awsAccessKeyId: '',
            awsSecretAccessKey: '',
            awsAccessUrl: '',
            azureConnectionString: '',
            userProfile: data.userProfile || '',
            cloudProfileId: data.cloudProfileId || '',
            azueTieringByModifiedDate: data.azueTieringByModifiedDate || false,
            azureTieringYear: data.azureTieringYear || '',
            azureArhciveTierPolicy: data.azureArhciveTierPolicy || null,
          },
          'POST',
          'file-stores',
          navigate
        )
      );
    }
  };

  return (
    <>
      <ConfirmModal openModal={openModal} close={() => setOpenModal(false)} updateDetails={updateDetails} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} md={11}>
          <TextField
            name="fileStoreType"
            label="File Store Tag"
            helperText={errors.fileStoreType && errors.fileStoreType.message}
            error={errors.fileStoreType && errors.fileStoreType.message}
            {...register('fileStoreType')}
            variant="standard"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </Grid>
        <Grid item xs={12} md={11}>
          <TextField
            name="fileStorePath"
            label="File Store Path"
            helperText={errors.fileStorePath && errors.fileStorePath.message}
            error={errors.fileStorePath && errors.fileStorePath.message}
            {...register('fileStorePath')}
            variant="standard"
            sx={{ width: '100%', marginTop: '15px' }}
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </Grid>
        <Grid item xs={12} md={11}>
          <Stack direction="row">
            <FormControlLabel
              control={<Checkbox checked={selectCheck} />}
              name="encryption"
              {...register('encryption')}
              label="Encryption enable/disable"
              sx={{ pt: 1, pb: 1 }}
              onChange={() => setSelectCheck(!selectCheck)}
            />
            {/* <FormControlLabel
              control={<Checkbox checked={nativeModeCheck} />}
              name="nativeMode"
              disabled
              {...register('nativeMode')}
              label="Store files in native mode"
              sx={{ pt: 1, pb: 1 }}
              onChange={(event) => setNativeModeCheck(!nativeModeCheck)}
            /> */}
          </Stack>
          <Grid item xs={12} md={11}>
            <Button type="submit" variant="contained" sx={{ color: '#ffff' }}>
              {createFlag ? 'Submit' : 'Update'}
            </Button>
            <Link to="/dashboard/volume-management" style={{ textDecoration: 'none' }}>
              <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }}>
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FileSystem;
