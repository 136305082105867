import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateAzureConfiguration, deleteAzureConfiguration } from '../ldapActions';

const schema = yup.object().shape({
  clientSecret: yup.string().required('required'),
  tenantId: yup.string().required('required'),
  clientId: yup.string().required('required'),
});

const AzureConfiguration = ({azureConfiguration }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if(!isEmpty(azureConfiguration)) {
      reset(azureConfiguration)
    }
    else {
      reset({
        clientId: '',
        clientSecret: '',
        tenantId: '',
      })
    }
  }, [azureConfiguration])

  const onDelete = (data) => {
    dispatch(deleteAzureConfiguration());
  };

  const onSubmit = (data) => {
    const companyId = localStorage.getItem('companyId');
    data.companyId = +companyId || 0;
    dispatch(updateAzureConfiguration(data));
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Card sx={{ p: '20px' }}>
          <CardHeader title="Azure Configuration" />
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ m: '10px' }}>
              <Grid item xs={12} md={3.5}>
                <TextField
                  name="clientId"
                  // value={azureConfiguration.clientId || ''}
                  label="Client Id"
                  helperText={errors.clientId && errors.clientId.message}
                  error={errors.clientId && errors.clientId.message}
                  {...register('clientId')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3.5}>
                <TextField
                  name="clientSecret"
                  label="Secret Client"
                  // value={azureConfiguration.clientSecret || ''}
                  helperText={errors.clientSecret && errors.clientSecret.message}
                  error={errors.clientSecret && errors.clientSecret.message}
                  {...register('clientSecret')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="tenantId"
                  label="Tenant Id"
                  // value={azureConfiguration.tenantId || ''}
                  helperText={errors.tenantId && errors.tenantId.message}
                  error={errors.tenantId && errors.tenantId.message}
                  {...register('tenantId')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // onChange={handleChange}
                  required
                />
              </Grid>


              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ color: '#ffff' }}
                //   disabled={documentClassifier.name === '' ? true : false}
                >
                  Update
                </Button>
                <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }} onClick={onDelete}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Container>
    </Page>
  );
};

export default AzureConfiguration;
