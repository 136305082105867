import { Button, Card, CardHeader, Container, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { getRoleAccess, updateRoleAccess } from 'src/redux/actions/loginActions';
import RolePermissionTable from './RolePermissionTable';

const RolePermissions = () => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { roleAccess } = useSelector((state) => state.login);
  const [fileExplorerData, setFileExplorerData] = useState([]);
  const [eDiscoveryData, setEDiscoveryData] = useState([]);
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    dispatch(getRoleAccess());
  }, []);

  useEffect(() => {
    setRoleList(roleAccess);
  }, [roleAccess]);

  useEffect(() => {
    let fileData = [];
    let discoveryData = [];
    roleList.length &&
      roleList.map((data) => {
        if (data.name.includes('fileExplorer')) {
          fileData.push(data);
        } else {
          discoveryData.push(data);
        }
      });
    setFileExplorerData([...fileData]);
    setEDiscoveryData([...discoveryData]);
  }, [roleList]);

  const handlePrivSelect = (e, row) => {
    let list = roleList;
    list.forEach((val) => {
      if (row.name == 'fileExplorer' && !row.privAllowed) {
        if (val.name.includes('fileExplorer')) {
          val.privAllowed = e.target.checked;
        }
      } else if (row.name == 'eDiscovery' && !row.privAllowed) {
        if (!val.name.includes('fileExplorer')) {
          val.privAllowed = e.target.checked;
        }
      } else if (val.id === row.id) {
        val.privAllowed = e.target.checked;
      }

      if(row.name == 'fileExplorer'){
        if(row.privAllowed == false){
          if(val.name == 'eDiscovery'){
            val.privAllowed = true
          }
        }
      }
      else if(row.name == 'eDiscovery'){
        if(row.privAllowed == true){
          if(val.name == 'fileExplorer'){
            val.privAllowed = true
          }
        }
      }
    });
    setRoleList([...list]);
  };
  const handleAdminSelect = (e, row) => {
    let list = roleList;
    list.forEach((val) => {
      if (row.name == 'fileExplorer' && !row.adminAllowed) {
        if (val.name.includes('fileExplorer')) {
          val.adminAllowed = e.target.checked;
        }
      } else if (row.name == 'eDiscovery' && !row.adminAllowed) {
        if (!val.name.includes('fileExplorer')) {
          val.adminAllowed = e.target.checked;
        }
      } else if (val.id === row.id) {
        val.adminAllowed = e.target.checked;
      }
    });
    setRoleList([...list]);
  };

  const handleLdapSelect = (e, row) => {
    let list = roleList;
    list.forEach((val) => {
      if (row.name == 'fileExplorer' && !row.ldapAllowed) {
        if (val.name.includes('fileExplorer')) {
          val.ldapAllowed = e.target.checked;
        }
      } else if (row.name == 'eDiscovery' && !row.ldapAllowed) {
        if (!val.name.includes('fileExplorer')) {
          val.ldapAllowed = e.target.checked;
        }
      } else if (val.id === row.id) {
        val.ldapAllowed = e.target.checked;
      }

      if(row.name == 'fileExplorer'){
        if(row.ldapAllowed == false){
          if(val.name == 'eDiscovery'){
            val.ldapAllowed = true
          }
        }
      }
      else if(row.name == 'eDiscovery'){
        if(row.ldapAllowed == true){
          if(val.name == 'fileExplorer'){
            val.ldapAllowed = true
          }
        }
      }
    });
    setRoleList([...list]);
  };

  const handleSubmit = () => {
    dispatch(updateRoleAccess(roleList));
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            <Card>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mr: '20px' }}>
                <CardHeader title="Role Permissions" sx={{ mb: 3 }} />
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </Stack>
              <RolePermissionTable
                roleAccess={roleList}
                title="File Explorer"
                handlePrivSelect={handlePrivSelect}
                handleLdapSelect={handleLdapSelect}
                handleAdminSelect={handleAdminSelect}
              />
              {/* <RolePermissionTable
                roleAccess={eDiscoveryData}
                title="E-Discovery"
                handlePrivSelect={handlePrivSelect}
                handleLdapSelect={handleLdapSelect}
              /> */}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default RolePermissions;
