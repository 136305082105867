import { Button, Card, CardHeader, Container, Grid, Modal, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { updateProfile } from '../volumeActions';

const AddAzureModal = ({ openAzureModal, close, fileStoreType }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();

  let companyId = localStorage.getItem('companyId');
  const [createData, setCreateData] = useState({
    companyId: +companyId,
    fileStoreBase: fileStoreType,
    name: '',
    azureConnectionString: '',
    awsAccessUrl: '',
  });

  const inputHandle = (e) => {
    setCreateData({ ...createData, [e.target.name]: e.target.value });
  };

  const createAzure = () => {
    dispatch(updateProfile(createData));
    close();
  };
  return (
    <>
      <Modal
        open={openAzureModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ backgroundColor: '#ffff', pb: '40px' }}>
              <CardHeader title="Azure" />

              <Grid container spacing={3} sx={{ mx: '40px' }}>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    label="Blob Storage Profile Name"
                    variant="standard"
                    name="name"
                    sx={{ width: '100%' }}
                    value={createData.name}
                    onChange={(e) => inputHandle(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    label="Storage Account Connection String"
                    variant="standard"
                    name="azureConnectionString"
                    sx={{ width: '100%' }}
                    value={createData.azureConnectionString}
                    onChange={(e) => inputHandle(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <Button
                    variant="contained"
                    sx={{ color: '#ffff', ml: '10px' }}
                    onClick={createAzure}
                    disabled={!(createData.name && createData.azureConnectionString)}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default AddAzureModal;
