import {
    JOB_FETCH_SUCCESS,
    DATA_LOADER,
    FETCH_FAIL,
    AGENTS_FETCH_SUCCESS,
    POLICIES_FETCH_SUCCESS,
    TIMESTAMP_FETCH_SUCCESS,
    EXCLUSION_FETCH_SUCCESS,
    UNC_PATH_FETCH_SUCCESS,
    DELETED_SUCCESSFULLY,
    JOB_INFO_FETCH_SUCCESS,
    FILE_TYPES_FETCH_SUCCESS,
    JOB_UPDATE_SUCCESS,
    SUCCESS,
    SHARES_FETCH_SUCCESS,
    SHARES_LOADER,
    TEST_ACCESS_SUCCESS,
    COPY_AGENTS_FETCH_SUCCESS,
    STUB_CHARTS_DATA,
    FILE_TYPES_DEFAULT_SUCCESS,
  } from './jobManagementActions';
  
  const initialState = {
    loading: false,
    jobs: {},
    agents: null,
    copyAgents: null,
    policies:[],
    exclusion:[],
    timeStamp: {},
    jobInfo: {},
    testData: {},
    uncPath: [],
    fileTypes: [],
    shares: [],
    defaultDetails:{},
    stubCharts: null,
    error: null,
  };
  
  const jobManagementReducer = (state = initialState, action) => {
    switch (action.type) {
      case DATA_LOADER: {
        return {
          ...state,
          loading: true,
          refresh: false,
          shares:[]
        };
      }
      case SHARES_LOADER: {
        return {
          ...state,
          shares:[]
        };
      }
      case JOB_FETCH_SUCCESS: {
        return {
          ...state,
          loading: false,
          jobs: action.payload,
        };
      }
      case AGENTS_FETCH_SUCCESS: {
        return {
          ...state,
          loading: false,
          agents: action.payload,
        };
      }
      case COPY_AGENTS_FETCH_SUCCESS: {
        return {
          ...state,
          loading: false,
          copyAgents: action.payload,
        };
      }
      case POLICIES_FETCH_SUCCESS: {
        return {
          ...state,
          loading: false,
          policies: action.payload,
        };
      }
      case EXCLUSION_FETCH_SUCCESS: {
        return {
          ...state,
          loading: false,
          exclusion: action.payload,
        };
      }
      case TIMESTAMP_FETCH_SUCCESS: {
        return {
          ...state,
          loading: false,
          timeStamp: action.payload,
        };
      }
      case SHARES_FETCH_SUCCESS: {
        return {
          ...state,
          loading: false,
          shares: action.payload.shares,
        };
      }
      case TEST_ACCESS_SUCCESS: {
        return {
          ...state,
          loading: false,
          testData: action.payload,
        };
      }
      case STUB_CHARTS_DATA: {
        return {
          ...state,
          loading: false,
          stubCharts: action.payload,
        };
      }
      case SUCCESS: {
        return {
          ...state,
          loading: false,
        };
      }
      case UNC_PATH_FETCH_SUCCESS: {
        return {
          ...state,
          loading: false,
          uncPath: action.payload,
        };
      }
      case JOB_INFO_FETCH_SUCCESS: {
        return {
          ...state,
          loading: false,
          jobInfo: action.payload,
        };
      }
      case FILE_TYPES_DEFAULT_SUCCESS: {
        return {
          ...state,
          loading: false,
          defaultDetails: action.payload,
        };
      }
      case FILE_TYPES_FETCH_SUCCESS: {
        return {
          ...state,
          loading: false,
          fileTypes: action.payload,
        };
      }
      case DELETED_SUCCESSFULLY: {
        return {
          ...state,
          loading: false,
          refresh: true,
        };
      }
      case FETCH_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default: {
        return state;
      }
    }
  };
  
  export default jobManagementReducer;
  