import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TablePagination,
  Typography,
  Stack,
  TextField,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import { getAuditUsers, downloadFile } from './auditAccessActions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copiedFunction from 'src/utils/copyMethod';
import { styled } from '@mui/styles';
import axiosInstance from 'src/utils/axios';
import { getHeaders } from 'src/utils/fetchServices';
import { BASE_URL } from 'src/config';

const DAYS = ['No Date', 'Custom', 'Today', 'Yesterday', 'This Week', 'This Month', 'This Year'];

export default function AuditAccessLog() {
  const users = useSelector((state) => state.auditAccessReducer.users);
  const dispatch = useDispatch();
  const theme = useTheme();

  let date = new Date();
  let EndDate = date.toISOString();
  let showEndDate = date.toISOString().slice(0, 10);
  let setDate = date.setDate(date.getDate() - 1);
  let setDate2 = date.setDate(date.getDate() - 1);
  let Today1 = moment(new Date(setDate).setHours(18, 30, 0)).format('YYYY-MM-DD HH:mm:ss');

  const [range, setRange] = useState('Today');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [filter, setFilter] = useState({
    lastRepoAccessedStartDate: Today1,
    lastRepoAccessedEndDate: EndDate,
    currentPage: page,
    itemsPerPage: rowsPerPage,
  });

  const [responseTime, setresponseTime] = useState('');

  const [newFilter, setnewFilter] = useState({
    guid: '',
    userId: '',
  });
  const [showDate, setShowdate] = useState({
    StartDate: '',
    EndDate: '',
  });

  const [exportId, setExportId] = useState()

  useEffect(() => {
    const headers = getHeaders()
    axiosInstance.get(`${BASE_URL}license`, { headers }).then(res => { })
  }, [])

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterDelay={600} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 13,
      fontWeight: 400,
      maxWidth: '500px',
    },
  }));
  const handleRange = (event) => {
    setRange(event.target.value);
  };

  useEffect(() => {
    if (range == 'Today') {
      let Today1 = moment(new Date(setDate).setHours(18, 30, 0)).format('YYYY-MM-DD HH:mm:ss');
      setFilter({ ...filter, lastRepoAccessedStartDate: Today1, lastRepoAccessedEndDate: EndDate });
      setShowdate({ StartDate: showEndDate, EndDate: showEndDate });
    } else if (range == 'Yesterday') {
      let Yesterday1 = moment(new Date(setDate2).setHours(18, 30, 0)).format('YYYY-MM-DD HH:mm:ss');
      let Yesterday2 = moment(new Date(setDate).setHours(18, 29)).format('YYYY-MM-DDTHH:mm:ss.SSS');
      let showYesterday = Yesterday2.slice(0, 10);
      setFilter({ ...filter, lastRepoAccessedStartDate: Yesterday1, lastRepoAccessedEndDate: Yesterday2 });
      setShowdate({ StartDate: showYesterday, EndDate: showYesterday });
    } else if (range == 'This Year') {
      let showfyear = date.setMonth(0) && date.setDate(1);
      let fullyear = date.setFullYear(date.getFullYear() - 1) && date.setMonth(11) && date.setDate(31);

      let fullyear1 = moment(new Date(fullyear).setHours(18, 30, 0)).format('YYYY-MM-DD HH:mm:ss');
      setFilter({ ...filter, lastRepoAccessedStartDate: fullyear1, lastRepoAccessedEndDate: EndDate });

      let showfullyear1 = moment(new Date(showfyear)).format('YYYY-MM-DD HH:mm:ss');
      let showYear = showfullyear1.slice(0, 10);
      setShowdate({ StartDate: showYear, EndDate: showEndDate });
    } else if (range == 'This Month') {
      let showMonth1 = moment(new Date(date.setDate(1))).format('YYYY-MM-DD HH:mm:ss');
      let Getmonth = date.setDate(0) && date.setMonth(date.getMonth());
      let Month1 = moment(new Date(Getmonth)).format('YYYY-MM-DD 18:30:00');
      let showMonth = showMonth1.slice(0, 10);
      setFilter({ ...filter, lastRepoAccessedStartDate: Month1, lastRepoAccessedEndDate: EndDate });
      setShowdate({ StartDate: showMonth, EndDate: showEndDate });
    } else if (range == 'This Week') {
      let weekDate = new Date();
      let weekD = weekDate.getDate() - weekDate.getDay();
      let showweekD = weekDate.getDate() - weekDate.getDay();
      let Week1 = moment(new Date(weekDate.setDate(weekD - 1)).setHours(18, 30, 0)).format('YYYY-MM-DD HH:mm:ss');
      setFilter({ ...filter, lastRepoAccessedStartDate: Week1, lastRepoAccessedEndDate: EndDate });

      let showWeek1 = moment(new Date(weekDate.setDate(showweekD))).format('YYYY-MM-DD HH:mm:ss');
      let showWeek = showWeek1.slice(0, 10);
      setShowdate({ StartDate: showWeek, EndDate: showEndDate });
    } else if (range == 'No Date') {
      setFilter({ currentPage: filter.currentPage, itemsPerPage: filter.itemsPerPage });
    }
  }, [range]);

  const handleStartDateChange = (event) => {
    setRange('Custom')
    let val = new Date(event.target.value);
    let startD = val.setDate(val.getDate() - 1)
    setFilter({ ...filter, lastRepoAccessedStartDate: moment(startD).format('YYYY-MM-DD 18:30:ss') });
  };
  const handleEndDateChange = (event) => {
    setRange('Custom')
    setFilter({ ...filter, lastRepoAccessedEndDate: moment(event.target.value).format('YYYY-MM-DDT18:30:ss.SSS') });
  };

  const optionChange = (event) => {
    setnewFilter({ ...newFilter, [event.target.name]: event.target.value });
    setFilter({ ...filter, [event.target.name]: event.target.value });
    setExportId({ [event.target.name]: event.target.value })
  };

  const handleSearchTime = (startTime, endTime) => {
    let ResponseTime = (endTime - startTime) / 1000;
    setresponseTime(ResponseTime);
  };

  const Search = () => {
    filter.currentPage = page + 1;
    if (filter.userId == '') {
      delete filter.userId;
      delete exportId.userId;
    }
    if (filter.guid == '') {
      delete filter.guid;
      delete exportId.guid;
    }
    dispatch(getAuditUsers(filter, handleSearchTime));
  };

  useEffect(() => {
    Search();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilter({ ...filter, currentPage: newPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setFilter({ ...filter, itemsPerPage: parseInt(event.target.value) });
  };

  const downloadCSV = () => {
    dispatch(
      downloadFile({
        ...exportId,
        lastRepoAccessedEndDate: filter.lastRepoAccessedEndDate,
        lastRepoAccessedStartDate: filter.lastRepoAccessedStartDate
      })
    );
  };

  return (
    <>
      <Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Audit Access Log" sx={{ mb: 3 }} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          marginBottom="10px"
          backgroundColor="#F4F6F8"
          padding="10px"
        >
          <FormControl variant="standard" sx={{ m: 1 }}>
            <InputLabel id="demo-simple-select-standard-label">Select Range</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={range}
              onChange={handleRange}
              label="Range"
              name="range"
            >
              {DAYS.map((options, i) => (
                <MenuItem key={i} value={options}>
                  {options}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {range == 'No Date' ? null : range == 'Custom' ? (
            <Box>
              <Stack direction='row'>
                <TextField
                  id="startDate"
                  type="date"
                  name="lastRepoAccessedStartDate"
                  label=" Start Date"
                  variant="standard"
                  InputProps={{ inputProps: { max: showEndDate } }}
                  onChange={handleStartDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="endDate"
                  type="date"
                  name="lastRepoAccessedEndDate"
                  label="End Date"
                  variant="standard"
                  sx={{ marginLeft: '20px' }}
                  onChange={handleEndDateChange}
                  InputProps={{ inputProps: { max: showEndDate } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Stack>
            </Box>
          ) : (
            <Box>
              <Stack direction='row'>
                <TextField
                  id="startDate"
                  type="date"
                  name="lastRepoAccessedStartDate"
                  label=" Start Date"
                  variant="standard"
                  value={showDate.StartDate}
                  onChange={handleStartDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="endDate"
                  type="date"
                  name="lastRepoAccessedEndDate"
                  label="End Date"
                  variant="standard"
                  sx={{ marginLeft: '20px' }}
                  value={showDate.EndDate}
                  onChange={handleEndDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Stack>
            </Box>
          )}
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-basic"
              name="guid"
              label="File name OR UUID"
              variant="standard"
              value={newFilter.guid}
              onInput={optionChange}
            />
            <TextField
              id="standard-basic"
              name="userId"
              label="User"
              variant="standard"
              value={newFilter.userId}
              onInput={optionChange}
            />
          </Box>
          <Button variant='contained' onClick={Search}>
            <SearchIcon /> Search
          </Button>
        </Stack>
        {users?.totalCount ? (
          <Stack
            fullWidth
            direction="row"
            justifyContent="space-between"
            sx={{
              p: '20px',
              m: '10px',
              backgroundColor: '#F4F6F8',
              borderRadius: '12px',
            }}
          >
            <Typography>
              Found {users?.totalCount} Record(s) <br />
              Search Time {responseTime} Second(s)
            </Typography>
            <Button variant="contained" sx={{ padding: '10px' }} onClick={downloadCSV}>Export to CSV</Button>
          </Stack>
        ) : null}
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Accessed Date/Time</TableCell>
                <TableCell>Path</TableCell>
                <TableCell>Access Type</TableCell>
                <TableCell>User Role</TableCell>
                <TableCell>Accessed By</TableCell>
              </TableRow>
            </TableHead>
            {users?.totalCount == 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No Data Found ...
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {users?.list &&
                  users?.list.length &&
                  users?.list.map((row) => (
                    <TableRow>
                      <TableCell>
                        <Typography sx={{ color: '#699ad4' }}>{moment(row.repoAccessedOn).format(' lll ')}</Typography>
                      </TableCell>
                      <TableCell>
                        <LightTooltip title={row.path}>
                          <Button
                            sx={{ minWidth: 'fit-content', p: 0 }}
                            onClick={() => copiedFunction(row.path, dispatch)}
                          >
                            <ContentCopyIcon />
                          </Button>
                        </LightTooltip>
                        {row.path.substr(0, 62) + '...'}
                      </TableCell>
                      <TableCell>{row.accessType}</TableCell>
                      <TableCell>{row.userRole}</TableCell>
                      <TableCell>{row.userId}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 100]}
            component="div"
            count={users?.totalCount && users?.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
    </>
  );
}
