import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TablePagination,
  Checkbox,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Scrollbar from '../../../../components/Scrollbar';
import { Link } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { dispatch } from 'src/redux/store';
import { deleteAgentInfo, approveAgent } from '../AgentActions';
import copiedFunction from 'src/utils/copyMethod';
import { useEffect, useState } from 'react';
import DeleteAlert from 'src/pages/DeleteAlert';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import jwt from 'jwt-decode';

const globalKey = localStorage.getItem('globalKey');

export default function AgentTable({
  pages,
  size,
  activeAgents,
  currentColumns,
  pendingColumns,
  reload,
  ftpUsers,
  sortData,
}) {
  const [rowsPerPage, setRowsPerPage] = size;
  const [page, setPage] = pages;
  const [refresh, setRefresh] = reload;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [agentIds, setAgentIds] = useState([]);
  const [sort, setSort] = sortData;
  const [arrow, setArrow] = useState(false);
  const [active, setActive] = useState();
  const [pendingAgents, setPendingAgents] = useState([]);
  const [globalKey, setGlobalKey] = useState("")

  useEffect(() => {
    setGlobalKey(localStorage.getItem('globalKey'))
  }, [activeAgents])

  useEffect(() => {
    let agents = activeAgents.content.filter((agent) => agent.active === 0);
    setPendingAgents([...agents])
  }, [activeAgents]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleDelete = (id) => {
    setDeleteDialog(true);
    setDeleteId(id);
  };

  const removeFileStore = () => {
    dispatch(deleteAgentInfo(deleteId));
  };

  const selectAllAgent = (e) => {
    if (e.target.checked) {
      setAgentIds(pendingAgents.map((agent) => ({ agentId: agent.id, profileId: agent.profileId })));
    } else {
      setAgentIds([]);
    }
  };

  const selectAgent = (e, row) => {
    if (e.target.checked) {
      let agents = pendingAgents;
      agents.map((agent) => {
        if (agent.id == row.id) {
          setAgentIds([...agentIds, { agentId: row.id, profileId: row.profileId }]);
        }
      });
    } else {
      let data = agentIds.filter((agent) => agent.agentId != row.id);
      setAgentIds([...data]);
    }
  };

  const access_token = localStorage.getItem('accessToken');
  const decodedToken = jwt(access_token);
  const admin = decodedToken.authorities[0];
  let userRole = localStorage.getItem('userRole');

  const TableSort = (i) => {
    setActive(i);
    setArrow(!arrow);
    if (i == 0) {
      if (!arrow) {
        setSort('name,desc');
      } else {
        setSort('name,asc');
      }
    }
  };

  const approve = () => {
    dispatch(approveAgent(agentIds));
  };

  const changeUserType = (e, row) => {
    let val = e.target.value;
    let agents = pendingAgents;
    let selectAgentIds = agentIds;
    agents.map((agent) => {
      if (row.id == agent.id) {
        agent.profileId = val.id;
        agent.userProfile = val;
      }
    });
    selectAgentIds.map((agent) => {
      if (row.id == agent.agentId) {
        agent.profileId = val.id;
      }
    });
    setAgentIds([...selectAgentIds]);
    setPendingAgents([...agents]);
  };

  const checkSelectedAgent = (row) => {
    let val = false;
    if (agentIds.length) {
      agentIds.map((agent) => {
        if (row.id == agent.agentId) {
          val = true;
          return val;
        }
      });
    }
    return val;
  };

  return (
    <>
      <Card>
        <DeleteAlert remove={removeFileStore} openDialog={[deleteDialog, setDeleteDialog]} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Current Active Agents" sx={{ mb: 3 }} />
          <Stack direction="row" alignItems="center">
            {admin == 'ADMINISTRATOR' || admin == 'SUPERADMIN' && userRole == 'superadmin' ? (
              <Stack direction="row" alignItems="center">
                <Typography variant="h6">
                  Global Key: <span style={{ color: '#699ad4' }}>{globalKey}</span>{' '}
                </Typography>
                <Button onClick={() => copiedFunction(globalKey, dispatch)}>
                  <ContentCopyIcon />
                </Button>
              </Stack>
            ) : null}
            <Button
              variant="contained"
              sx={{ color: '#ffff', height: '40px', m: '20px' }}
              onClick={() => setRefresh(!refresh)}
            >
              Refresh
            </Button>
          </Stack>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {currentColumns.map((column, i) => (
                    <TableCell key={i}>
                      <Stack direction="row" onClick={() => TableSort(i)}>
                        <Typography style={{ marginRight: '8px' }}>{column}</Typography>
                        {i < 1 && active == i ? (
                          arrow == true ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )
                        ) : i < 1 ? (
                          <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                        ) : null}
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {activeAgents &&
                  activeAgents.content.map(
                    (row) =>
                      row.active === 1 && (
                        <TableRow key={row.id}>
                          <TableCell>
                            <Typography sx={{ color: '#699ad4' }}>{row.name}</Typography>
                          </TableCell>
                          <TableCell sx={{ maxWidth: '250px' }}>
                            {row.encryptedID ? (
                              <Button
                                sx={{ minWidth: 'fit-content', p: 0 }}
                                onClick={() => copiedFunction(row.encryptedID, dispatch)}
                              >
                                <ContentCopyIcon />
                              </Button>
                            ) : (
                              ''
                            )}
                            {row.encryptedID.slice(0, 30)}
                            {row.encryptedID ? '...' : ''}
                          </TableCell>
                          <TableCell>{row.type}</TableCell>
                          <TableCell>{row.allowedIps}</TableCell>
                          <TableCell>{row.online === true ? 'Online' : 'Offline'}</TableCell>
                          <TableCell sx={{ maxWidth: '100px' }}>
                            <Link to={`edit/${row.id}`}>
                              <Button sx={{ minWidth: 'fit-content', p: 0 }}>
                                <ModeEditIcon />
                              </Button>
                            </Link>

                            <Button sx={{ minWidth: 'fit-content', p: 0 }} onClick={() => handleDelete(row.id)}>
                              <DeleteForeverIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                  )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={activeAgents.totalElements - pendingAgents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>

      <Card sx={{ mt: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Agents Pending Activation" sx={{ mb: 3 }} />
          {pendingAgents.length == 0 ? null : (
            <Button
              variant="contained"
              sx={{ color: '#ffff', height: '40px', m: '20px' }}
              onClick={() => approve(pendingAgents)}
              disabled={!agentIds.length}
            >
              Approve
            </Button>
          )}
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {pendingColumns.map((column, i) => (
                    <TableCell key={i}>{column}</TableCell>
                  ))}
                  <TableCell>
                    <Checkbox onChange={(e) => selectAllAgent(e)} />
                  </TableCell>
                </TableRow>
              </TableHead>
              {pendingAgents.length == 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Data Found ...
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {pendingAgents.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography sx={{ color: '#699ad4' }}>{row.name}</Typography>
                      </TableCell>
                      <TableCell sx={{ maxWidth: '250px' }}>
                        {row.encryptedID.slice(0, 30)}...
                        <Button onClick={() => copiedFunction(row.encryptedID, dispatch)}>
                          <ContentCopyIcon />
                        </Button>
                      </TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.allowedIps}</TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel id="label">Select User</InputLabel>
                          <Select
                            labelId="label"
                            // value={age}
                            label="Select User"
                            variant="standard"
                            size="small"
                            onChange={(e) => changeUserType(e, row)}
                          >
                            {ftpUsers && ftpUsers.map((user) => <MenuItem value={user}>{user.profileName}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell sx={{ maxWidth: '100px' }}>
                        <Checkbox checked={checkSelectedAgent(row)} onChange={(e) => selectAgent(e, row)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={pendingAgents.length}
              rowsPerPage={10}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
