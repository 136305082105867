import { Card, CardContent, CardHeader, Container } from '@mui/material';
import React from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import Typography from '@mui/material/Typography';

const styles = {
  treeView: {
    listStyleType: "none",
    paddingLeft: 10,
  },
  card: {
    maxHeight: '600px',
    overflow: 'auto',
  }
}

const RootNodes = ({ rootNodes, fetchChildrenNodes, getFolderView, getClassName, versionInfo }) => {
  const { themeStretch } = useSettings();
  const [versionData, setVersionData] = versionInfo;

  const renderChildrenNodes = (nodes) => {
    return (
      <ul style={styles.treeView}>
        {nodes.map((node, i) => (
          <li key={i}>
            <div style={{ display: "flex" }}>
              <Typography
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  node.loading = true;
                  fetchChildrenNodes(node);
                }}
              >
                {node.expand ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                <FolderRoundedIcon sx={{ color: '#fda92d' }} />
              </Typography>
              <div>
                <span style={{ display: "flex", gap: 3 }}>
                  <i className="folderIcon"></i> <Typography sx={{ cursor: 'pointer' }} onClick={() => { getFolderView(node, '', 1); setVersionData([]) }}>{node.name}</Typography>
                </span>
                {node.loading && <Typography>Loading...</Typography>}
              </div>
            </div>
            {node.expand && node.childNodes?.length ? renderChildrenNodes(node.childNodes) : null}
          </li>
        ))}
      </ul>
    )
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Card sx={styles.card}>
          <CardHeader title="Folders" sx={{ mb: '20px' }} />
          <CardContent>
            {renderChildrenNodes(rootNodes)}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default RootNodes;
