import { AZURE_FETCH_SUCCESS, LDAP_FETCH_SUCCESS, DATA_LOADER, FETCH_FAIL, POST_SUCCESS } from './ldapActions';

const initialState = {
  loading: false,
  ldapConfiguration: {},
  azureConfiguration: {},
  error: null,
};

const ldapReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case LDAP_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        ldapConfiguration: action.payload,
      };
    }
    case AZURE_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        azureConfiguration: action.payload,
      };
    }
    case POST_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default ldapReducer;
