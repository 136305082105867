import { Container, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { getRoleAccess } from 'src/redux/actions/loginActions';
import SearchCriteria from './components/SearchCriteria';

export default function EDiscovery() {
  const dispatch = useDispatch()
  const { themeStretch } = useSettings();
  const { roleAccess } = useSelector((state) => state.login);

  useEffect(()=> {
    dispatch(getRoleAccess());
  }, [])
  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} sx={{ pt:0 }}>
           <SearchCriteria roleAccess={roleAccess} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
