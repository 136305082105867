import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import chartColors from 'src/utils/chartColors';
import ChartDataModal from '../analytics/components/ChartDataModal';
import { getChartsVolume } from './AnalyticsPathAction';
import { getRoleAccess } from 'src/redux/actions/loginActions';

const AnalyticsPaths = () => {
  const dispatch = useDispatch();
  const dates = useSelector((state) => state.systemReducer.dates);
  const chartsVolume = useSelector((state) => state.analyticsReducers.chartsVolume);
  const loading = useSelector((state) => state.analyticsReducers.loading);
  const [barChartYearPathData, setBarChartYearPathData] = useState([]);
  const [startDate, setStartDate] = useState(new Date('1995/01/01'));
  const [endDate, setEndDate] = useState(new Date());
  const [chartData, setChartData] = useState([]);
  const [openModal, setopenModal] = useState(false);
  const [path, setPath] = useState(null);

  useEffect(() => {
    let unixStartDate = moment(dates.startDate).format('x');
    let unixEndDate = new Date(dates.endDate).setHours(23, 59, 59);
    dispatch(getChartsVolume({ startDate: unixStartDate, endDate: unixEndDate, dateAnalysis: 'MODIFIED' }));
    dispatch(getRoleAccess());
  }, []);

  useEffect(() => {
    let pathKey = [];
    let singleLabelBucket = [];
    let labelBucket = [];
    let yearLabelByPath = [];
    let totalPathYearLabels = [];
    let barChartPerYearDataPath = [];
    let barChartPerYearLabelsPath = [];
    let barChartData = [];

    if (chartsVolume['aggregations'] !== undefined) {
      for (let bucket of chartsVolume['aggregations'][2].buckets) {
        for (let item of bucket[3].buckets) {
          for (let place of item[4].buckets) {
            if (pathKey.indexOf(place.key) === -1) {
              pathKey.push(place.key);
            }
          }
        }
      }
      for (let path of pathKey) {
        for (let bucket of chartsVolume['aggregations'][2].buckets) {
          let year = new Date(bucket.key).getFullYear().toString();
          if (parseInt(year) >= startDate.getFullYear() && parseInt(year) <= endDate.getFullYear()) {
            for (let item of bucket[3].buckets) {
              for (let place of item[4].buckets) {
                if (path === place.key) {
                  if (singleLabelBucket.indexOf(item.key) === -1) {
                    singleLabelBucket.push(item.key);
                  }
                }
              }
            }
          }
        }
        labelBucket.push(singleLabelBucket);
        singleLabelBucket = [];
      }
      for (let path of pathKey) {
        for (let singleKey of labelBucket[pathKey.indexOf(path)]) {
          var data = [];
          for (let bucket of chartsVolume['aggregations'][2].buckets) {
            let year = new Date(bucket.key).getFullYear().toString();
            if (parseInt(year) >= startDate.getFullYear() && parseInt(year) <= endDate.getFullYear()) {
              for (let item of bucket[3].buckets) {
                var bucketName = item.key;
                if (bucketName === singleKey) {
                  for (let place of item[4].buckets) {
                    if (path === place.key) {
                      if (yearLabelByPath.indexOf(year) === -1) {
                        yearLabelByPath.push(year);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        yearLabelByPath.sort();
        totalPathYearLabels.push(yearLabelByPath);
        yearLabelByPath = [];
      }
      for (let path of pathKey) {
        let i = 0;
        for (let singleKey of labelBucket[pathKey.indexOf(path)]) {
          var data = [];
          var bucketName = '';
          var singleLabelYear = [];

          for (let bucket of chartsVolume['aggregations'][2].buckets) {
            let year = new Date(bucket.key).getFullYear().toString();
            if (parseInt(year) >= startDate.getFullYear() && parseInt(year) <= endDate.getFullYear()) {
              for (let item of bucket[3].buckets) {
                bucketName = item.key;
                if (bucketName === singleKey) {
                  for (let place of item[4].buckets) {
                    if (path === place.key) {
                      if (barChartPerYearLabelsPath.indexOf(year) === -1) {
                        barChartPerYearLabelsPath.push(year);
                      }
                      singleLabelYear = totalPathYearLabels[pathKey.indexOf(path)];
                      data[singleLabelYear.indexOf(year)] = place[1].value / (1024 * 1024 * 1024);
                    }
                  }
                }
              }
            }
          }
          barChartPerYearDataPath.push({
            data: data,
            label: singleKey,
            backgroundColor: chartColors[i++],
          });
        }
        barChartPerYearLabelsPath.sort();
        barChartData = [
          ...barChartData,
          {
            data: barChartPerYearDataPath,
            label: barChartPerYearLabelsPath,
            pathKey: path,
          },
        ];
        barChartPerYearDataPath = [];
        barChartPerYearLabelsPath = [];
      }
      setBarChartYearPathData(barChartData);
    }
  }, [chartsVolume]);

  useEffect(() => {
    if (path) {
      barChartYearPathData &&
        barChartYearPathData.map((item, i) => {
          if (item.pathKey == path) {
            setopenModal(true);
            setChartData(item);
          }
        });
    } else {
      setopenModal(false);
      setPath(null);
    }
  }, [path]);

  const openChart = (item) => {
    setopenModal(true);
    setChartData(item);
  };
  return (
    <>
      <ChartDataModal openModal={openModal} close={() => setopenModal(false)} data={chartData} />
      {!loading ? (
        <Page>
          <Card sx={{ p: 1 }}>
            {/* <CardHeader title="Stacked Chart Volume Per Type/Year (Modified)" /> */}
            <Container maxWidth="1500px" sx={{ display: 'flex', flexWrap: 'wrap', my: 4 }}>
              <Autocomplete
                // freeSolo
                name="remoteUnc"
                options={barChartYearPathData.length ? barChartYearPathData.map((option) => option.pathKey) : []}
                onChange={(e) => setPath(e.target.textContent)}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Select Analysed Path"
                    onChange={(e) => {
                      // setPath(e.target.value);
                    }}
                  />
                )}
                fullWidth
              />
            </Container>
            <Grid container spacing={3} sx={{ mb: '20px' }}>
              {barChartYearPathData && barChartYearPathData.length ? (
                barChartYearPathData.map((item, index) => (
                  <Grid item xs={12} md={4}>
                    <Card
                      onClick={() => openChart(item)}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '100px',
                        p: '10px',
                      }}
                    >
                      <Typography sx={{ fontWeight: '600', wordBreak: 'break-all' }} variant="h6">
                        {item.pathKey}
                      </Typography>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} md={4}>
                  <Card
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minHeight: '100px',
                    }}
                  >
                    <Typography sx={{ fontWeight: '600' }} variant="h6">
                      No Data Found
                    </Typography>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Card>
        </Page>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default AnalyticsPaths;
