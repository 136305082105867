import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAwsRegion, getProfileInfo, updateProfile } from '../volumeActions';
import { isEmpty } from 'lodash';
import TestAccessModal from '../../job-management/components/TestAccessModal';
import { testAccess } from '../../job-management/jobManagementActions';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';

const AddProfile = () => {
  const { themeStretch } = useSettings();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const region = useSelector((state) => state.volumeReducer.region);
  const profileInfo = useSelector((state) => state.volumeReducer.profileInfo);
  const loading = useSelector((state) => state.volumeReducer.loading);
  const [createFlag, setCreateFlag] = useState(true);
  const [customAccess, setCustomAccess] = useState(false)
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [validTest, setValidTest] = useState(false);
  const testData = useSelector((state) => state.jobManagementReducer.testData);
  const [customAccessArr, setCustomAccessArr] = useState(['Add Custom', 'https://s3.wasabisys.com', 's3.amazonaws.com'])
  const [testAccessLoading, setTestAccessLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState({
    awsAccessKeyId: '',
    awsAccessUrl: '',
    awsSecretAccessKey: '',
    azureConnectionString: '',
    companyId: +localStorage.getItem('companyId'),
    fileStoreBase: 2,
    filestoreRegionName: '',
    fipsEnabled: false,
    id: 0,
    name: '',
    usedForDyanmoDb: false,
  });
  const [cloudForm, setCloudForm] = useState("");

  const azureSchema = {
    name: yup.string()
      .max(50, 'Name is too long')
      .required('Name is required')
      .matches(/^[a-zA-Z0-9\s\-#@$%&*!,.]+$/, 'Invalid characters in name'),
    azureConnectionString: yup.string().required('required'),
  };
  const s3Schema = {
    name: yup.string()
      .max(50, 'Name is too long')
      .required('Name is required')
      .matches(/^[a-zA-Z0-9\s\-#@$%&*!,.]+$/, 'Invalid characters in name'),
    awsAccessUrl: yup.string().required('required'),
    awsAccessKeyId: yup.string().required('required'),
    awsSecretAccessKey: yup.string().required('required'),
    filestoreRegionName: yup.string().required('required'),
  };

  const smbSchema = {
    name: yup.string()
      .max(50, 'Name is too long')
      .required('Name is required')
      .matches(/^[a-zA-Z0-9\s\-#@$%&*!,.]+$/, 'Invalid characters in name'),
    uncpath: yup.string().required('required'),
    domain: yup.string().required('required'),
    username: yup.string().required('required'),
    password: yup.mixed().required('required'),
  };
  const checkSchema = () => {
    if (params.type === 'azure' || profileInfo.fileStoreBase == 3) {
      return azureSchema;
    } else if (params.type === 'WindowsSMB' || profileInfo.fileStoreBase == 4) {
      return smbSchema
    }
    else {
      return s3Schema;
    }
  };

  useEffect(() => {
    dispatch(getAwsRegion());
  }, []);
  useEffect(() => {
    let { type, id } = params;
    if (id) {
      // User Edit
      dispatch(getProfileInfo(id));
      setCreateFlag(false);
    } else {
      // Add
      setCloudForm(type && type === 'azure' ? "AZURE" : type === 's3' ? "S3" : "SMB");
      setCreateFlag(true);
    }
  }, []);

  const schema = yup.object().shape({ ...checkSchema() });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (params.id) {
      if (!isEmpty(profileInfo)) {
        reset(profileInfo);
        setCloudForm(profileInfo.fileStoreBase == 3 ? "AZURE" : profileInfo.fileStoreBase == 4 ? "SMB" : "S3");
        checkSchema();
      }
    }
  }, [profileInfo]);

  const accessSelection = (e) => {
    if (e.target.value == 'Add Custom') {
      setValue('awsAccessUrl', "")
      setCustomAccess(false)
    } else {
      setValue('awsAccessUrl', e.target.value)
      setCustomAccess(true)
    }
  }

  useEffect(() => {
    let editUrl = watch('awsAccessUrl')
    let selectLabel = customAccessArr.includes(editUrl);
    if (selectLabel) {
      setCustomAccess(true)
    } else {
      setCustomAccess(false)
    }

  }, [params.id, watch('awsAccessUrl')])

  const onSubmit = (data) => {
    let companyId = localStorage.getItem('companyId');
    if (params.id) {
      dispatch(updateProfile(data, 'PUT', `cloud-profile`, navigate));
    } else {
      data.companyId = +companyId;
      // data.id = 0;
      // data.azureConnectionString = '';
      data.fileStoreBase = params.type === 'azure' ? 3 : params.type === 's3' ? 2 : 4;
      dispatch(updateProfile(data, 'POST', `cloud-profile`, navigate));
    }
  };

  const getAccessData = async () => {
    setTestAccessLoading(true)
    if (watch('uncpath').slice(0, 2) == '\\\\' && watch('uncpath').split('\\').length > 3) {
      await dispatch(
        testAccess({
          domain: watch('domain'),
          userName: watch('username'),
          password: watch('password'),
          agentId: 0,
          uncPath: watch('uncpath'),
          libType: 'JNQ',
        })
      );
      setValidTest(true)
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please enter a valid path',
      });
    }
    setTestAccessLoading(false)
  };

  useEffect(() => {
    if (validTest && testData) {
      setOpenAccessModal(true);
    }
  }, [testData])

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <TestAccessModal
          testData={testData}
          openModal={openAccessModal}
          close={() => { setOpenAccessModal(false) }}
          loader={testAccessLoading}
        />
        {!loading ? (
          <Card sx={{ p: '20px' }}>
            <CardHeader title={createFlag ? 'Add Profile' : 'Edit Profile'} />
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} sx={{ m: '10px' }}>
                {cloudForm == "SMB" ?
                  <>
                    <Grid item xs={12} md={5.5}>
                      <TextField
                        name="name"
                        label="Name"
                        helperText={errors.name && errors.name.message}
                        error={errors.name && errors.name.message}
                        {...register('name')}
                        variant="standard"
                        sx={{ width: '100%', marginTop: '10px' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} md={5.5}>
                      <TextField
                        name="uncpath"
                        label="File Store Path"
                        helperText={errors.uncpath && errors.uncpath.message}
                        error={errors.uncpath && errors.uncpath.message}
                        {...register('uncpath')}
                        variant="standard"
                        sx={{ width: '100%', marginTop: '10px' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} md={5.5}>
                      <TextField
                        label="Domain"
                        name="domain"
                        helperText={errors.domain && errors.domain.message}
                        error={errors.domain && errors.domain.message}
                        {...register('domain')}
                        variant="standard"
                        sx={{ width: '100%', marginTop: '10px' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                      <TextField
                        label="Username"
                        name="username"
                        variant="standard"
                        helperText={errors.username && errors.username.message}
                        error={errors.username && errors.username.message}
                        {...register('username')}
                        sx={{ width: '100%', marginTop: '10px' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                      <TextField
                        name="password"
                        type="password"
                        label="Password"
                        variant="standard"
                        helperText={errors.password && errors.password.message}
                        error={errors.password && errors.password.message}
                        {...register('password')}
                        sx={{ width: '100%', marginTop: '10px' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                      {/* { watch('password')?.includes('@') ?
                            <Typography variant="caption" color="red" sx={{width:'fit-content'}}>
                            @ symbol in password is not accepted, please update the password and try again
                            </Typography> : null
                          } */}
                    </Grid>
                  </>
                  : cloudForm == "AZURE" ? (
                    <>
                      <Grid item xs={12} md={5.5}>
                        <TextField
                          name="name"
                          label="Blob Storage Profile Name"
                          helperText={errors.name && errors.name.message}
                          error={errors.name && errors.name.message}
                          {...register('name')}
                          variant="standard"
                          sx={{ width: '100%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={5.5}>
                        <TextField
                          name="azureConnectionString"
                          label="Storage Account Connection String"
                          helperText={errors.azureConnectionString && errors.azureConnectionString.message}
                          error={errors.azureConnectionString && errors.azureConnectionString.message}
                          {...register('azureConnectionString')}
                          variant="standard"
                          sx={{ width: '100%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // onChange={handleChange}
                          required
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={5.5}>
                        <TextField
                          name="name"
                          label="Name"
                          helperText={errors.name && errors.name.message}
                          error={errors.name && errors.name.message}
                          {...register('name')}
                          variant="standard"
                          sx={{ width: '100%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // onChange={handleChange}
                          required
                        />
                      </Grid>
                      {!watch('fipsEnabled') ? (
                        <Grid item xs={12} md={5.5}>
                          <InputLabel sx={{ fontSize: "0.7rem" }} id="awsAccessUrl" >Access Url</InputLabel>
                          <Stack direction={"row"} gap={3} >
                            <Select
                              name="awsAccessUrl"
                              labelId="awsAccessUrl"
                              variant="standard"
                              value={customAccess ? watch('awsAccessUrl') : 'Add Custom'}
                              onChange={(e) => accessSelection(e)}
                              sx={{ width: "100%", height: 'fit-content' }}
                            >
                              {/* <MenuItem value="Add Custom">Add Custom</MenuItem>
                      <MenuItem value="s3.wasabysis.com">s3.wasabysis.com</MenuItem>
                      <MenuItem value="s3.amazonaws.com">s3.amazonaws.com</MenuItem> */}
                              {customAccessArr && customAccessArr.length ?
                                customAccessArr.map((item, i) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))
                                : null}
                            </Select>

                            <TextField
                              name="awsAccessUrl"
                              placeholder='Add Custom'
                              helperText={errors.awsAccessUrl && errors.awsAccessUrl.message}
                              error={errors.awsAccessUrl && errors.awsAccessUrl.message}
                              disabled={customAccess}
                              {...register('awsAccessUrl')}
                              variant="standard"
                              sx={{ width: '100%', }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required
                            />
                          </Stack>
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={5.5}></Grid>
                      )}
                      <Grid item xs={12} md={5.5}>
                        <TextField
                          name="azureConnectionString"
                          type="password"
                          label="Access Key Id"
                          helperText={errors.awsAccessKeyId && errors.awsAccessKeyId.message}
                          error={errors.awsAccessKeyId && errors.awsAccessKeyId.message}
                          {...register('awsAccessKeyId')}
                          variant="standard"
                          sx={{ width: '100%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={5.5}>
                        <TextField
                          name="awsSecretAccessKey"
                          type="password"
                          label="Secret Access Key"
                          helperText={errors.awsSecretAccessKey && errors.awsSecretAccessKey.message}
                          error={errors.awsSecretAccessKey && errors.awsSecretAccessKey.message}
                          {...register('awsSecretAccessKey')}
                          variant="standard"
                          sx={{ width: '100%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // onChange={handleChange}
                          required
                        />
                      </Grid>
                      {region.length && (
                        <Grid item xs={12} md={5.5}>
                          <FormControl fullWidth>
                            <InputLabel id="reg">Region</InputLabel>
                            <Select
                              labelId="user"
                              name="filestoreRegionName"
                              variant="standard"
                              value={watch('filestoreRegionName') || ''}
                              label="Region"
                              error={errors.filestoreRegionName && errors.filestoreRegionName.message}
                              {...register('filestoreRegionName')}
                              // onChange={handleChange}
                              required
                            >
                              {region.length &&
                                region.map((reg, i) => {
                                  return (
                                    <MenuItem key={i} value={reg.name}>
                                      {reg.name}-{reg.description}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} md={5.5}>
                        <FormControlLabel
                          name="usedForDyanmoDb"
                          control={<Checkbox />}
                          label="Use profile for DynamoDB"
                          {...register('usedForDyanmoDb')}
                        // onChange={(e) =>
                        //   setProfileDetails({ ...profileDetails, [e.target.name]: !profileDetails.usedForDyanmoDb })
                        // }
                        />
                      </Grid>
                      <Grid item xs={12} md={5.5}>
                        <FormControlLabel
                          name="fipsEnabled"
                          control={<Checkbox />}
                          label="Enable FIPS"
                          {...register('fipsEnabled')}
                        // onChange={(e) =>
                        //   setProfileDetails({ ...profileDetails, [e.target.name]: !profileDetails.fipsEnabled })
                        // }
                        />
                      </Grid>
                    </>
                  )}
                <Grid item xs={12} md={12}>
                  {cloudForm == "SMB" &&
                    <Button
                      variant="contained"
                      sx={{ color: '#ffff', mr: '10px' }}
                      onClick={() => { getAccessData() }}
                    >
                      SMB Test
                    </Button>}
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: '#ffff' }}
                  //   disabled={documentClassifier.name === '' ? true : false}
                  >
                    {createFlag ? 'Submit' : 'Update'}
                  </Button>
                  <Link to="/dashboard/volume-management/profile" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }}>
                      Back
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Card>
        ) : (
          <LoadingScreen />
        )}
      </Container>
    </Page>
  );
};

export default AddProfile;
