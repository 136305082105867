import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardHeader, Checkbox, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addNewCompany, getCompanyInfo, updateCompany } from '../companyActions';

const LicenseSchema = yup.object().shape({
  companyName: yup.string().required('required'),
  companyPreferredName: yup.string().required(' required'),
  companySupportEmail: yup.string().email('Invalid email companyContactName').required('required'),
  companyTelephone: yup.string().required('required'),
  companyContactName: yup.string().required('required'),
  companyAddress: yup.string().required('required'),
  companySupportContact: yup.string().required('required'),
  companyTag: yup.string().required('required'),
  companyUsername: yup.string().required('required'),
  companyPassword: yup.string().required('required'),
  confirmPassword: yup
    .string()
    .required('required')
    .oneOf([yup.ref('companyPassword'), null], 'Passwords must match'),
});

const AddCompany = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const companyInfo = useSelector((state) => state.companyReducer.companyInfo);
  const [index, setIndex] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    id: 0,
    companyLicense: '',
    loginPageLogo: '',
    addressBarLogo: '',
    addressBarLogoSearch: '',
    repoName: '',
    repoPath: '',
    fileExplorer: '',
    noOfShards: 10,
    noOfReplicas: 2,
    indexAnalyzer: 'english',
    globalKey: '',
    backupInterval: 0,
    backupTime: '',
    backupEmailList: '',
    backupRollback: 0,
    ucompanyTag: '',
    allowedStorageLimitGb: '',
    localAgent: false,
  });

  useEffect(() => {
    if (params.id) {
      dispatch(getCompanyInfo(params.id));
    }
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(LicenseSchema),
  });

  useEffect(() => {
    if (params.id) {
      if (!isEmpty(companyInfo)) {
        reset(companyInfo);
        setIndex(companyInfo.indexContent ? true : false);
      }
    }
  }, [companyInfo]);

  const onSubmit = (data) => {
    let details = { ...companyDetails, ...data };
    details.indexContent = index ? 1 : 0;
    if (params.id) {
      dispatch(updateCompany(data, navigate));
    } else {
      dispatch(addNewCompany(details, navigate));
    }
  };

  return (
    <Card>
      <CardHeader title="Basic Configuration" />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} sx={{ mt: 1,p:5,pt:0}}>
          <Grid item xs={12} md={6}>
            <TextField
              name="companyName"
              label="Company Name"
              variant="standard"
              helperText={errors.companyName && errors.companyName.message}
              error={errors.companyName && errors.companyName.message}
              {...register('companyName')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
            }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="companyPreferredName"
              label="Company Prefered Name"
              variant="standard"
              helperText={errors.companyPreferredName && errors.companyPreferredName.message}
              error={errors.companyPreferredName && errors.companyPreferredName.message}
              {...register('companyPreferredName')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
            }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="companyTag"
              label="Company Tag"
              variant="standard"
              helperText={errors.companyTag && errors.companyTag.message}
              error={errors.companyTag && errors.companyTag.message}
              {...register('companyTag')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
            }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="companyContactName"
              label="Company Contact Name"
              variant="standard"
              helperText={errors.companyContactName && errors.companyContactName.message}
              error={errors.companyContactName && errors.companyContactName.message}
              {...register('companyContactName')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
            }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="companyAddress"
              label="Company Address"
              variant="standard"
              helperText={errors.companyAddress && errors.companyAddress.message}
              error={errors.companyAddress && errors.companyAddress.message}
              {...register('companyAddress')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
            }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="companyTelephone"
              label="Company Telephone"
              type="number"
              variant="standard"
              helperText={errors.companyTelephone && errors.companyTelephone.message}
              error={errors.companyTelephone && errors.companyTelephone.message}
              {...register('companyTelephone')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
            }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="companySupportContact"
              label="Support Contact Name"
              variant="standard"
              helperText={errors.companySupportContact && errors.companySupportContact.message}
              error={errors.companySupportContact && errors.companySupportContact.message}
              {...register('companySupportContact')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
            }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="companySupportEmail"
              label="Support Email"
              variant="standard"
              helperText={errors.companySupportEmail && errors.companySupportEmail.message}
              error={errors.companySupportEmail && errors.companySupportEmail.message}
              {...register('companySupportEmail')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
            }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="companyUsername"
              label="Administrator Username"
              variant="standard"
              helperText={errors.companyUsername && errors.companyUsername.message}
              error={errors.companyUsername && errors.companyUsername.message}
              {...register('companyUsername')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
            }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="companyPassword"
              label="Administrator Password"
              type="password"
              variant="standard"
              helperText={errors.companyPassword && errors.companyPassword.message}
              error={errors.companyPassword && errors.companyPassword.message}
              {...register('companyPassword')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
            }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              variant="standard"
              helperText={errors.confirmPassword && errors.confirmPassword.message}
              error={errors.confirmPassword && errors.confirmPassword.message}
              {...register('confirmPassword')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
            }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center">
              <Checkbox checked={index} onChange={(e) => setIndex(e.target.checked)} />
              <Typography>Index Content</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Button variant="contained" type="submit" sx={{ color: '#ffff' }}>
              {params.id ? 'Update' : 'Save'}
            </Button>
            <Link to="/dashboard/company-list" style={{ textDecoration: 'none' }}>
              <Button variant="contained" sx={{ color: '#ffff', m: '10px' }}>
                Cancel
              </Button>
            </Link>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default AddCompany;
