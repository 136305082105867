import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from 'src/hooks/useSettings';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import { getAzureConfiguration, getLdapConfiguration } from './ldapActions';
import LdapConfig from './compoonents/LdapConfig';
import AzureConfiguration from './compoonents/AzureConfiguration';

export default function LdapConfiguration() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const ldapConfiguration = useSelector((state) => state.ldapReducer.ldapConfiguration);
  const azureConfiguration = useSelector((state) => state.ldapReducer.azureConfiguration);
  const loading = useSelector((state) => state.ldapReducer.loading);

  useEffect(() => {
    dispatch(getLdapConfiguration());
    dispatch(getAzureConfiguration());
  }, []);

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {!loading ? (
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
              <LdapConfig ldapConfiguration={ldapConfiguration} />
            </Grid>
            <Grid item xs={12} md={12} sx={{mt:'10px' }}>
              <AzureConfiguration azureConfiguration={azureConfiguration} />
            </Grid>
          </Grid>
        ) : (
          <LoadingScreen />
        )}
      </Container>
    </Page>
  );
}
