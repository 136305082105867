import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import AgentTable from './components/AgentTable';
import { getActiveAgents } from './AgentActions';
import LoadingScreen from 'src/components/LoadingScreen';
import { getFTPUsers } from 'src/pages/user-groups/user-management/usersActions';

export default function ArchiveAgents() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const activeAgents = useSelector((state) => state.agentReducer.activeAgents);
  const ftpUsers = useSelector((state) => state.usersReducer.ftpUsers);
  const loading = useSelector((state) => state.agentReducer.loading);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [sort,setSort]= useState('id,desc')

  useEffect(() => {
    dispatch(getActiveAgents({ page, size: rowsPerPage,sort:sort }));
  }, [page, rowsPerPage, refresh,sort]);

  useEffect(()=> {
    dispatch(getFTPUsers())
  },[refresh])

  const currentColumns = ['Name', 'Encoded Key', 'Agent Type', 'Source IP', 'Status', 'Action'];
  const pendingColumns = ['Name', 'Encoded Key', 'Agent Type', 'Source IP', 'FTP User'];

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            { !loading ? (
              activeAgents.content &&
              <AgentTable
                pages={[page, setPage]}
                size={[rowsPerPage, setRowsPerPage]}
                activeAgents={activeAgents}
                ftpUsers={ftpUsers}
                currentColumns={currentColumns}
                pendingColumns={pendingColumns}
                reload={[refresh, setRefresh]}
                sortData = {[sort,setSort]}
              />
            ): <LoadingScreen/>}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
