import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';

const SharesModal = ({ shares, openModal, close, pathList }) => {
  const [shareState, setShareState] = useState([]);
  const { themeStretch } = useSettings();
  const [remotePathInList, setRemotePathInList] = pathList;
  const [selectAll, setSelectAll] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const selectedShares = (e, share) => {
    if (e.target.checked) {
      setShareState([...shareState, share]);
    } else {
      setSelectAll(false);
      setShareState(shareState.filter((s) => s !== share));
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked && shares.length) {
      setSelectAll(true);
      let data = [];
      shares.map((share) => {
        if (share.charAt(share.length - 1) != '$' || isHidden) {
          data.push(share);
        }
      });
      setShareState([...data]);
    } else {
      setSelectAll(false);
      setShareState([]);
    }
  };

  useEffect(() => {
    if (selectAll && isHidden) {
      setShareState([...shares]);
    } else if (selectAll && !isHidden) {
      let data = [];
      shares?.length &&
        shares.map((share) => {
          if (share.charAt(share.length - 1) != '$' || isHidden) {
            data.push(share);
          }
        });
      setShareState([...data]);
    }
  }, [selectAll, isHidden]);
  const handleCheck = (share) => {
    let val = false;
    if (shareState.includes(share)) {
      val = true;
      return val;
    }
    return val;
  };

  const addShares = () => {
    setRemotePathInList([...remotePathInList, ...shareState]);
    setSelectAll(false);
    setIsHidden(false);
    close();
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ backgroundColor: '#ffff', pb: '40px' }}>
              <CardHeader title="Shares" sx={{ mb: '20px' }} />
              <Grid container spacing={3} sx={{ mx: '20px' }}>
                <Grid item xs={12} md={11}>
                  <Card sx={{ p: '20px' }}>
                    {shares.length == 0 ? (
                      <Stack alignItems="center" justifyContent="center">
                        <CircularProgress />
                      </Stack>
                    ) : (
                      <Stack direction="row" gap={2}>
                        <Stack direction="row" sx={{ mb: '5px' }}>
                          <Checkbox checked={selectAll} sx={{ padding: 0 }} onChange={(e) => handleSelectAll(e)} />
                          <Typography sx={{ marginLeft: '5px' }}>Select All</Typography>
                        </Stack>
                        <Stack direction="row" sx={{ mb: '5px' }}>
                          <Checkbox checked={isHidden} sx={{ padding: 0 }} onChange={() => setIsHidden(!isHidden)} />
                          <Typography sx={{ marginLeft: '5px' }}>Show hidden Shares</Typography>
                        </Stack>
                      </Stack>
                    )}
                    <Grid container spacing={1} sx={{ overflow: 'auto', maxHeight: '300px' }}>
                      {shares.length
                        ? shares.map(
                            (share) =>
                              (share.charAt(share.length - 1) != '$' || isHidden) && (
                                <Grid item xs={12} md={4}>
                                  <Stack direction="row">
                                    <Checkbox
                                      checked={handleCheck(share)}
                                      sx={{ padding: 0 }}
                                      onChange={(e) => selectedShares(e, share)}
                                    />
                                    <Typography
                                      sx={{ wordBreak: 'break-all', marginLeft: '5px', paddingRight: '20px' }}
                                    >
                                      {share}
                                    </Typography>
                                  </Stack>
                                </Grid>
                              )
                          )
                        : null}
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }} onClick={addShares}>
                    Add
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ color: '#ffff', ml: '10px' }}
                    onClick={() => {
                      setSelectAll(false);
                      setIsHidden(false);
                      close();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default SharesModal;
