import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { ERROR_ALERT, SUCCESS_ALERT, PROGRESS_DONE, PROGRESS_START } from 'src/redux/reducers/alertReducer';
import { GetDetails, GetFetch, postInfo } from 'src/utils/fetchServices';
export const DATA_LOADER = 'DATA_LOADER';
export const POST_SUCCESS = 'POST_SUCCESS';
export const AGENTS_FETCH_SUCCESS = 'AGENTS_FETCH_SUCCESS';
export const GLOBAL_FETCH_SUCCESS = 'GLOBAL_FETCH_SUCCESS';
export const EXPORT_FILES_SUCCESS = 'EXPORT_FILES_SUCCESS';
export const USER_INFO = 'USER_INFO';
export const SIMILAR_DOC = 'SIMILAR_DOC';
export const FETCH_FAIL = 'FETCH_FAIL';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const HISTORY_SEARCH_SUCCESS = 'HISTORY_SEARCH_SUCCESS';
export const SEARCH_LOADER = 'SEARCH_LOADER';
export const FILE_VIEW_DATA = 'FILE_VIEW_DATA';

export const getUserInfo = () => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await GetDetails({ url: `export-request-tracker/companyId/${companyId}/user` });
    const data = response.data;
    dispatch({
      type: USER_INFO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const getAgents = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: DATA_LOADER,
  });
  params.active = 1;
  try {
    const response = await GetFetch({ url: `agents/company/${companyId}`, params });
    const data = response.data;
    dispatch({
      type: AGENTS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getUserHistory = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  const access_token = localStorage.getItem('accessToken');
  let userName = ''

  if (access_token) {
    const decodedToken = jwt(access_token);
    userName = decodedToken?.user_name
  }
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetFetch({ url: `search-query-history/company/${companyId}/user-name/${userName}`, params });
    const data = response.data;
    dispatch({
      type: HISTORY_SEARCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getGlobalConfiguration = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `system-codes/global-configuration/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: GLOBAL_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getSimilarDoc = (params, uid, handleSearchTime) => async (dispatch) => {
  let startTime = new Date().getTime();
  dispatch({
    type: PROGRESS_START,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `elasticsearch/similar-docs/${companyId}/${uid}`, params });
    if (response.status === 200) {
      const data = response.data;
      dispatch({
        type: SIMILAR_DOC,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
  let endTime = new Date().getTime();
  handleSearchTime(startTime, endTime);
};

export const searchData = (body, handleSearchTime) => async (dispatch) => {
  let startTime = new Date().getTime();
  dispatch({
    type: PROGRESS_START,
  });
  dispatch({
    type: POST_SUCCESS,
    payload: {},
  });
  dispatch({
    type: SEARCH_LOADER,
  });
  try {
    const response = await postInfo({ url: `elasticsearch/serach`, body });
    if (response.status === 200) {
      const data = response.data;
      dispatch({
        type: POST_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({ type: FETCH_FAIL });
      dispatch({
        type: ERROR_ALERT,
        payload: 'Something Went Wrong',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
  let endTime = new Date().getTime();
  handleSearchTime(startTime, endTime);
};
export const exportAllFiles = (body, companyId) => async (dispatch) => {
  dispatch({
    type: PROGRESS_START,
  });
  body.currentPage = 1;
  body.itemsPerPage = 500;
  try {
    const response = await postInfo({ url: `elasticsearch/serach`, body });
    if (response.status === 200) {
      const data = response.data;
      let files = !isEmpty(data) ? data.results : [];
      let selectID;
      if (data.results.length) {
        selectID = files.map((file) => file.plainUuid);
        dispatch(backenExportFiles({ guids: selectID, companyId }));
      }
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Something Went Wrong',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};
export const exportFiles = (body) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await postInfo({ url: `jobs/export-searched`, body });
    if (response.status === 200) {
      const data = response.data;
      dispatch({
        type: EXPORT_FILES_SUCCESS,
        payload: data,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Success',
      });
    } else {
      dispatch({ type: FETCH_FAIL });
      dispatch({
        type: ERROR_ALERT,
        payload: 'Something Went Wrong',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
};

export const backenExportFiles = (body) => async (dispatch) => {
  // dispatch({
  //   type: DATA_LOADER,
  // });
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo({ url: `file-explorer/export-file`, body });
    if (response.status === 200) {
      const data = response.data;
      dispatch({
        type: EXPORT_FILES_SUCCESS,
        payload: data,
      });
      // dispatch({
      //   type: SUCCESS_ALERT,
      //   payload: 'Success',
      // });
    } else {
      dispatch({ type: FETCH_FAIL });
      dispatch({
        type: ERROR_ALERT,
        payload: 'Something Went Wrong',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const restoreFileData = (body) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await postInfo({ url: `file-explorer/restore-file`, body });
    if (response.status === 200) {
      const data = response.data;
      dispatch({
        type: EXPORT_FILES_SUCCESS,
        payload: data,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Success',
      });
    } else {
      dispatch({ type: FETCH_FAIL });
      dispatch({
        type: ERROR_ALERT,
        payload: 'Something Went Wrong',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
};

export const downloadFile = (params, name) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await GetFetch({
      url: `file-explorer/download-file/company/${companyId}`,
      params,
      responseType: 'blob',
    });
    const data = response.data;
    var blob = new Blob([data]);
    let url = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', name);
    tempLink.click();
    dispatch({
      type: DOWNLOAD_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Files exported successfully',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const DocumentViewFile = (uuid) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  const params = {
    convertToPdf: true
  }
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await GetFetch({
      url: `file-explorer/company/${companyId}/document/${uuid}`,
      params
    });
    const data = response.data;
    dispatch({
      type: FILE_VIEW_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const downloadZipFile = (body) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo({
      url: `file-explorer/download-files/company/${companyId}`,
      body,
      responseType: 'blob',
    });
    var blob = new Blob([response.data]);
    let url = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', 'data.zip');
    tempLink.click();
    // dispatch({
    //   type: DOWNLOAD_SUCCESS,
    //   payload: data,
    // });
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Files exported successfully',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const downloadResultCSV = (body) => async (dispatch) => {
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo({
      url: `elasticsearch/serach/export-to-csv`,
      body,
    });
    var blob = new Blob([response.data]);
    let url = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', 'e-discovery.csv');
    tempLink.click();
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Files exported successfully',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const downloadExportPageFile = (id) => async (dispatch) => {
  // const companyId = localStorage.getItem('companyId');
  dispatch({
    type: PROGRESS_START,
  });

  let date = new Date().toLocaleDateString();
  try {
    const response = await GetDetails({
      url: `export-request-tracker/${id}/download/`,
      responseType: 'blob',
    });
    const data = response.data;
    var blob = new Blob([data]);
    let url = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', `Export_${date}.zip`);
    tempLink.click();
    dispatch({
      type: DOWNLOAD_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Files exported successfully',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};
