import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import AddAzureModal from './AddAzureModal';
import { updateProfile } from '../volumeActions';
import { useDispatch } from 'react-redux';
import ConfirmModal from './ConfirmModal';

const AzureBlobStore = ({ userProfile, fileStoreInfo, createFlag, fileStoreType }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});
  const [checked, setChecked] = useState(false);
  const [openAzureModal, setOpenAzureModal] = useState(false);
  const [nativeModeCheck, setNativeModeCheck] = useState(false);
  const fileObject = [
    { name: 'Last Accessed Date', value: 'BY_LAST_ACCESS_DATE' },
    { name: 'Modified date', value: 'BY_MODIFIED_DATE' },
  ];

  const schema = yup.object().shape({
    fileStoreType: yup.string().required('required'),
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setNativeModeCheck(watch('nativeMode'));
  }, [watch('nativeMode')]);

  useEffect(() => {
    if (params.id) {
      if (!isEmpty(fileStoreInfo)) {
        reset(fileStoreInfo);
        setChecked(fileStoreInfo.markAsDefault);
      }
    }
  }, [fileStoreInfo]);

  const onSubmit = (data) => {
    let companyId = localStorage.getItem('companyId');
    if (params.id) {
      let editData = {
        awsAuthorizedPassword: data.awsAuthorizedPassword || null,
        awsDefaultBasket: 0,
        azureArhciveTierPolicy: data.azureArhciveTierPolicy || null,
        azureTieringYear: data.azureTieringYear || '',
        cloudProfileId: data.cloudProfileId || '',
        companyId: +companyId,
        companyName: data.companyName,
        encryption: data.encryption || false,
        fileStoreBase: +fileStoreType,
        fileStoreName: data.fileStoreType,
        fileStorePath: data.fileStorePath || '',
        fileStoreTempPath: data.fileStoreTempPath || '',
        fileStoreType: data.fileStoreType,
        nativeMode: data.nativeMode || false,
        id: data.id,
        markAsDefault: data.markAsDefault || false,
        writable: true,
      };
      setUpdateDetails(editData);
      setOpenModal(true);
    } else {
      dispatch(
        updateProfile(
          {
            awsAccessKeyId: '',
            awsAccessUrl: '',
            awsAuthorizedPassword: '',
            awsDefaultBasket: 0,
            awsSecretAccessKey: '',
            azueTieringByModifiedDate: data.azueTieringByModifiedDate || false,
            nativeMode: data.nativeMode || false,
            azureArhciveTierPolicy: data.azureArhciveTierPolicy || null,
            azureConnectionString: '',
            azureTieringYear: data.azureTieringYear || '',
            cloudProfileId: data.cloudProfileId || '',
            companyId: +companyId,
            encryption: data.encryption || false,
            fileStoreBase: +fileStoreType,
            fileStoreName: data.fileStoreType,
            fileStorePath: data.fileStorePath || '',
            fileStoreRegionId: data.fileStoreRegionId || 0,
            fileStoreRegionName: data.fileStoreRegionName || '',
            fileStoreTempPath: '',
            fileStoreType: data.fileStoreType,
            id: 0,
            markAsDefault: data.markAsDefault || false,
            userProfile: data.userProfile || '',
            writable: true,
          },
          'POST',
          'file-stores',
          navigate
        )
      );
    }
  };
  return (
    <>
      <AddAzureModal
        openAzureModal={openAzureModal}
        close={() => setOpenAzureModal(!openAzureModal)}
        fileStoreType={fileStoreType}
      />
      <ConfirmModal openModal={openModal} close={() => setOpenModal(false)} updateDetails={updateDetails} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <Grid item xs={12} md={11}>
            <TextField
              name="fileStoreType"
              label="Container Name"
              variant="standard"
              helperText={errors.fileStoreType && errors.fileStoreType.message}
              error={errors.fileStoreType && errors.fileStoreType.message}
              {...register('fileStoreType')}
              sx={{ width: '100%' }}
              required
            />
          </Grid>

          <Grid item xs={12} md={11}>
            <Stack direction={{ md: 'row', sm: 'column' }} spacing={2}>
              <FormControl sx={{ flex: 5 }} fullWidth>
                <InputLabel id="user" sx={{ marginLeft: '-15px' }}>
                  Select Azure Profile
                </InputLabel>
                <Select
                  name="azureProfile"
                  labelId="user"
                  value={watch('cloudProfileId') || ''}
                  variant="standard"
                  label="Select Azure Profile"
                  {...register('cloudProfileId')}
                >
                  {userProfile.length
                    ? userProfile.map((user) => {
                      return (
                        <MenuItem key={user.id} value={user.id}>
                          {user.name}
                        </MenuItem>
                      );
                    })
                    : null}
                </Select>
              </FormControl>
              <Button variant="contained" sx={{ color: '#ffff', flex: 1 }} onClick={() => setOpenAzureModal(true)}>
                Add Azure Blob Store Account
              </Button>
            </Stack>
          </Grid>
        </Stack>
        <Grid item xs={12} md={11}>
          <Stack direction="row">
            <FormControlLabel
              control={<Checkbox />}
              checked={checked}
              label="Archive Setting"
              name="markAsDefault"
              onChange={(e) => {
                setValue('markAsDefault', !checked);
                setChecked(!checked);
              }}
            />
            <FormControlLabel
              control={<Checkbox checked={nativeModeCheck} />}
              name="nativeMode"
              {...register('nativeMode')}
              label="Store files in native mode"
              sx={{ pt: 1, pb: 1 }}
              onChange={(event) => setNativeModeCheck(!nativeModeCheck)}
            />
          </Stack>
        </Grid>
        {checked && (
          <>
            <Stack direction="row" alignItems="center" gap={2}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="file">File objects with</InputLabel>
                  <Select
                    name="azureArhciveTierPolicy"
                    value={watch('azureArhciveTierPolicy') || ''}
                    labelId="file"
                    variant="standard"
                    label="File objects with"
                    {...register('azureArhciveTierPolicy')}
                  >
                    {fileObject.map((file, i) => {
                      return (
                        <MenuItem key={i} value={file.value}>
                          {file.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  type="number"
                  label="Older than"
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="azureTieringYear"
                  {...register('azureTieringYear')}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>Years, Move To Archive Tier</Typography>
              </Grid>
            </Stack>
          </>
        )}
        <Grid item xs={12} md={11} sx={{ mt: '20px' }}>
          {/* <Button variant="contained" sx={{ color: '#ffff' }} onClick={() => setOpenAzureModal(true)}>
            Add Azure Blob Store Account
          </Button> */}
          <Button type="submit" variant="contained" sx={{ color: '#ffff', ml: '10px' }}>
            {createFlag ? 'Submit' : 'Update'}
          </Button>
          <Link to="/dashboard/volume-management" style={{ textDecoration: 'none' }}>
            <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }}>
              Back
            </Button>
          </Link>
        </Grid>
      </form>
    </>
  );
};

export default AzureBlobStore;
