import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, getFtpUserInfo, getRoles, getUserInfo } from '../usersActions';
import PathTable from './PathTable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import Iconify from 'src/components/Iconify';

const UserSchema = {
  userName: yup.string()
    .min(3, 'User name is too short')
    .max(20, 'User name is too long')
    .required('User name is required')
    .matches(/^[a-zA-Z0-9\s\-#@$%&*!]+$/, 'Invalid characters in username'),
  firstName: yup.string()
    .max(50, 'First name is too long')
    .required('First name is required')
    .matches(/^[a-zA-Z0-9\s\-#@$%&*!]+$/, 'Invalid characters in name'),
  email: yup.string().nullable().email('Invalid email address'),
  lastName: yup.string()
    .max(50, 'Last name is too long')
    .required('Last name is required')
    .matches(/^[a-zA-Z0-9\s\-#@$%&*!]+$/, 'Invalid characters in name'),
  roleId: yup.string().required('required'),
  lockStatus: yup.string().required('required'),
};
const UserSchemaEdit = {
  userName: yup.string()
    .min(3, 'User name is too short')
    .max(20, 'User name is too long')
    .required('User name is required')
    .matches(/^[a-zA-Z0-9\s\-#@$%&*!_]+$/, 'Invalid characters in username'),
  firstName: yup.string()
    .max(50, 'First name is too long')
    .required('First name is required')
    .matches(/^[a-zA-Z0-9\s\-#@$%&*!]+$/, 'Invalid characters in name'),
  email: yup.string().nullable().email('Invalid email address'),
  lastName: yup.string()
    .max(50, 'Last name is too long')
    .required('Last name is required')
    .matches(/^[a-zA-Z0-9\s\-#@$%&*!]+$/, 'Invalid characters in name'),
  roleId: yup.string().required('required'),
  lockStatus: yup.string().required('required'),
  passwordHash: yup.string().required('required'),
  confirmPassword: yup
    .string()
    .required('required')
    .oneOf([yup.ref('passwordHash'), null], 'Passwords must match'),
};

const FTPSchema = {
  password: yup.string().required('required'),
  profileName: yup.string().required('required'),
};

const lockStatus = ['Active', 'In-Active'];
const includeColumn = ['Include', 'Action'];
const excludeColumn = ['Exclude', 'Action'];

const AddUser = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const roles = useSelector((state) => state.usersReducer.roles);
  const userInfo = useSelector((state) => state.usersReducer.userInfo);
  const ftpUserInfo = useSelector((state) => state.usersReducer.ftpUserInfo);
  const [uncPath, setUncPath] = useState('');
  const [isFtp, setIsFtp] = useState(false);
  const [uncPathInList, setUncPathInList] = useState([]);
  const [uncPathExList, setUncPathExList] = useState([]);
  const [userDetails, setUserDetails] = useState({
    excludePaths: uncPathExList,
    id: 0,
    includePaths: uncPathInList,
    localGroups: [{}],
  });
  const [createFlag, setCreateFlag] = useState(true);
  const [disableBtn, setDisableBtn] = useState(true);
  const [validUNC, setValidUNC] = useState(false);
  const [updateDisable, setUpdateDisable] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  useEffect(() => {
    if (userInfo.includePaths == null) {
      setUncPathInList([]);
    } else {
      setUncPathInList(userInfo.includePaths);
    }
    if (userInfo.excludePaths == null) {
      setUncPathExList([]);
    } else {
      setUncPathExList(userInfo.excludePaths);
    }
  }, [userInfo]);

  const checkSchema = () => {
    if (isFtp) {
      return FTPSchema;
    } else {
      if (createFlag) {
        return UserSchemaEdit;
      } else {
        return UserSchema;
      }
    }
  };

  const Schema = yup.object().shape({ ...checkSchema() });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    let id = params.id;
    let ftpId = params.ftpId;
    if (id) {
      // User Edit
      dispatch(getUserInfo(id));
      setCreateFlag(false);
    } else if (ftpId) {
      // FTP Edit
      setIsFtp(true);
      dispatch(getFtpUserInfo(1));
      setCreateFlag(false);
    } else {
      // Add
      setCreateFlag(true);
    }
  }, []);

  useEffect(() => {
    if (!createFlag) {
      if (isFtp) {
        let ftpId = params.ftpId;
        if (!isEmpty(ftpUserInfo)) {
          // const ftpUser = ftpUserInfo.find((user) => user.id === ftpId);
          const ftpUser = ftpUserInfo.find((user) => user.id == ftpId);
          reset(ftpUser);
        }
      } else {
        if (!isEmpty(userInfo)) {
          reset({
            id: userInfo.id,
            email: userInfo.email || '',
            firstName: userInfo.firstName || '',
            lastName: userInfo.lastName || '',
            lockStatus: userInfo.lockStatus || '',
            passwordHash: userInfo.passwordHash || '',
            confirmPassword: userInfo.confirmPassword || '',
            userName: userInfo.userName || '',
            companyId: userInfo.companyId || 0,
            userRolesStr: userInfo.userRolesStr || '',
            resetPassword: userInfo.resetPassword || '',
            confirmResetPassword: userInfo.confirmResetPassword || '',
            lastPasswordChange: userInfo.lastPasswordChange || '',
            roleId: userInfo.roleId || '',
            roleName: userInfo.roleName || '',
            readOnly: userInfo.readOnly || '',
            readWrite: userInfo.readWrite || '',
            twoFaEnabled: userInfo.twoFaEnabled || '',
            includePaths: userInfo.includePaths || '',
            excludePaths: userInfo.excludePaths || '',
          });
        }
      }
    }
  }, [userInfo, ftpUserInfo]);

  useEffect(() => {
    if (createFlag) {
      if (isFtp) {
        reset({
          profileName: '',
          password: '',
        });
      } else {
        reset({
          userName: '',
          firstName: '',
          email: '',
          lastName: '',
          roleId: '',
          lockStatus: '',
        });
      }
    }
  }, [isFtp]);

  const onSubmit = (data) => {
    const companyId = localStorage.getItem('companyId');
    if (createFlag) {
      if (isFtp) {
        dispatch(
          addUser(
            {
              profileName: data.profileName,
              password: data.password,
              companyId: +companyId,
            },
            'ftpprofile',
            '',
            navigate
          )
        );
      } else {
        dispatch(
          addUser(
            {
              id: 0,
              userName: data.userName,
              roleId: +data.roleId,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              lockStatus: data.lockStatus,
              passwordHash: data.passwordHash,
              confirmPassword: data.confirmPassword,
              localGroups: userDetails.localGroups || [],
              companyId: +companyId,
              includePaths: uncPathInList || [],
              excludePaths: uncPathExList || [],
            },
            '',
            '',
            navigate
          )
        );
      }
    } else {
      if (isFtp) {
        dispatch(
          addUser(
            {
              profileName: data.profileName,
              password: data.password,
              companyId: +companyId,
              port: getValues('port'),
            },
            `ftpprofile/${params.ftpId}`,
            'PUT',
            navigate
          )
        );
      } else {
        dispatch(
          addUser(
            {
              id: getValues('id'),
              userName: data.userName,
              roleId: +data.roleId,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              lockStatus: data.lockStatus,
              passwordHash: data.passwordHash,
              confirmPassword: data.confirmPassword,
              companyId: +companyId,
              includePaths: uncPathInList || [],
              excludePaths: uncPathExList || [],
              userRolesStr: getValues('userRolesStr'),
              resetPassword: getValues('resetPassword'),
              lastPasswordChange: getValues('lastPasswordChange'),
              confirmResetPassword: getValues('confirmResetPassword'),
              roleName: getValues('roleName'),
              readOnly: getValues('readOnly'),
              readWrite: getValues('readWrite'),
              twoFaEnabled: getValues('twoFaEnabled'),
            },
            '',
            'PUT',
            navigate
          )
        );
      }
    }
  };

  const includePath = () => {
    let path;
    path = uncPath;
    if (path.indexOf('\\') == -1) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please enter a valid path',
      });
      return;
    }
    if (!checkIncludedPath(path)) {
      setUncPathInList([...uncPathInList, path]);
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Path already added to Included list',
      });
    }
  };

  const checkIncludedPath = (path) => {
    let check = false;
    uncPathInList.forEach((obj) => {
      if (path == obj) check = true;
    });
    return check;
  };

  const removeIncludedPath = (index) => {
    uncPathInList.splice(index, 1);
    setUncPathInList([...uncPathInList]);
  };

  const changePath = (e) => {
    let val = e.target.value;
    setUncPath(val);
    if (val !== '') {
      setDisableBtn(false);
      let valid = val.slice(0, 2);
      if (valid == '\\\\') {
        setValidUNC(false);
        // setUpdateDisable(false)
      } else {
        setValidUNC(true);
      }
    } else if (val == '') {
      setDisableBtn(true);
    }
  };

  const excludePath = () => {
    let path;
    path = uncPath;
    if (path.indexOf('\\') == -1) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please enter a valid path',
      });
      return;
    }
    if (!checkExcludedPath(path)) {
      setUncPathExList([...uncPathExList, path]);
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Path already added to Excluded list',
      });
    }
  };

  const checkExcludedPath = (path) => {
    let check = false;
    uncPathExList.forEach((obj) => {
      if (path == obj) check = true;
    });
    return check;
  };

  const removeExcludedPath = (index) => {
    uncPathExList.splice(index, 1);
    setUncPathExList([...uncPathExList]);
  };

  return (
    <Card>
      <CardHeader title={createFlag ? 'Add User' : 'Edit User'} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ m: '40px', mt: 1 }}>
          {createFlag ? (
            <Grid item xs={12} md={11}>
              <FormControlLabel
                control={<Checkbox />}
                checked={isFtp}
                label="FTP User"
                onChange={() => setIsFtp(!isFtp)}
              />
            </Grid>
          ) : null}

          {!isFtp ? (
            <>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="userName"
                  // value={userDetails.userName}
                  label="User Name"
                  variant="standard"
                  helperText={errors.userName && errors.userName.message}
                  error={errors.userName && errors.userName.message}
                  {...register('userName')}
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="email"
                  // value={userDetails.email}
                  label="Email"
                  variant="standard"
                  helperText={errors.email && errors.email.message}
                  error={errors.email && errors.email.message}
                  {...register('email')}
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="firstName"
                  // value={userDetails.firstName}
                  label="First Name"
                  variant="standard"
                  helperText={errors.firstName && errors.firstName.message}
                  error={errors.firstName && errors.firstName.message}
                  {...register('firstName')}
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="lastName"
                  // value={userDetails.lastName}
                  label="Last Name"
                  variant="standard"
                  helperText={errors.lastName && errors.lastName.message}
                  error={errors.lastName && errors.lastName.message}
                  {...register('lastName')}
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                // onChange={(e) => handleChange(e)}
                />
              </Grid>
              {createFlag ? (
                <>
                  <Grid item xs={12} md={5.5}>
                    <TextField
                      name="passwordHash"
                      type="password"
                      // value={userDetails.passwordHash}
                      label="Password"
                      variant="standard"
                      helperText={errors.passwordHash && errors.passwordHash.message}
                      error={errors.passwordHash && errors.passwordHash.message}
                      {...register('passwordHash')}
                      sx={{ width: '100%' }}
                    // onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <TextField
                      name="confirmPassword"
                      type="password"
                      // value={userDetails.confirmPassword}
                      label="Confirm Password"
                      variant="standard"
                      helperText={errors.confirmPassword && errors.confirmPassword.message}
                      error={errors.confirmPassword && errors.confirmPassword.message}
                      {...register('confirmPassword')}
                      sx={{ width: '100%' }}
                    // onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} md={5.5}>
                <FormControl fullWidth>
                  <InputLabel id="status" sx={{ marginLeft: '-15px' }}>
                    Lock Status
                  </InputLabel>
                  <Select
                    name="lockStatus"
                    labelId="status"
                    value={watch('lockStatus') || ''}
                    variant="standard"
                    label="Lock Status"
                    helperText={errors.lockStatus && errors.lockStatus.message}
                    error={errors.lockStatus && errors.lockStatus.message}
                    {...register('lockStatus')}
                  // onChange={handleChange}
                  >
                    {lockStatus.map((status, i) => {
                      return (
                        <MenuItem key={i} value={status}>
                          {status}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5.5}>
                <FormControl fullWidth>
                  <InputLabel id="role" sx={{ marginLeft: '-15px' }}>
                    Role
                  </InputLabel>
                  <Select
                    name="roleId"
                    labelId="role"
                    // value={global.roleId}
                    value={parseInt(getValues('roleId')) || ''}
                    variant="standard"
                    label="Role"
                    helperText={errors.roleId && errors.roleId.message}
                    error={errors.roleId && errors.roleId.message}
                    {...register('roleId')}
                  // onChange={handleChange}
                  >
                    {roles.map((role) => {
                      return (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {watch('roleId') === 2 && (
                <>
                  <Grid item xs={12} md={11}>
                    <TextField
                      name="uncPath"
                      //   value={userDetails.lastName || ''}
                      label="UNC(e.g. \\server\folder)"
                      variant="standard"
                      helperText={errors.uncPath && errors.uncPath.message}
                      error={errors.uncPath && errors.uncPath.message}
                      // {...register('uncPath')}
                      sx={{ width: '100%' }}
                      onChange={(e) => changePath(e)}
                    />
                    {validUNC ? (
                      <Typography sx={{ color: 'red', fontSize: '12px' }}>UNC Path Should Be Valid</Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={11}>
                    <Button
                      variant="contained"
                      sx={{ color: '#ffff' }}
                      disabled={disableBtn}
                      onClick={() => includePath()}
                    >
                      Include
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ color: '#ffff', m: '10px' }}
                      disabled={disableBtn}
                      onClick={() => excludePath()}
                    >
                      Exclude
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <PathTable columns={includeColumn} rows={uncPathInList} handleDelete={removeIncludedPath} />
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <PathTable columns={excludeColumn} rows={uncPathExList} handleDelete={removeExcludedPath} />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={5}>
                <Button variant="contained" type="submit" sx={{ color: '#ffff' }}>
                  {createFlag ? 'Save' : 'Update'}
                </Button>
                <Link to="/dashboard/user-management" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" sx={{ color: '#ffff', m: '10px' }}>
                    Back
                  </Button>
                </Link>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="profileName"
                  // value={ftpUser.profileName}
                  label="FTP Profile Name"
                  variant="standard"
                  sx={{ width: '100%' }}
                  {...register('profileName')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                // onChange={(e) => handleFtpChange(e)}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  // value={ftpUser.password}
                  label="FTP Password"
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register('password')}
                // onChange={(e) => handleFtpChange(e)}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Button variant="contained" sx={{ color: '#ffff' }} type="submit">
                  {createFlag ? 'Save' : 'Update'}
                </Button>
                <Link to="/dashboard/user-management" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" sx={{ color: '#ffff', m: '10px' }}>
                    Back
                  </Button>
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </Card>
  );
};

export default AddUser;
