import { Button, Card, Container, Modal, Stack, Typography } from '@mui/material';
import React from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import LoadingScreen from 'src/components/LoadingScreen';

const TestAccessModal = ({ openModal, close, testData, loader }) => {
  const { themeStretch } = useSettings();

  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '400px' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            {loader ?
              <LoadingScreen />
              :
              <Card sx={{ backgroundColor: '#ffff' }}>
                <Card
                  sx={{
                    p: '20px',
                    m: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Stack direction="row">
                    <Typography variant="h6">Mount Status : </Typography>
                    {testData.mountStatus === 'failed' ? <CloseIcon sx={{ color: 'red' }} /> : <DoneIcon sx={{ color: "green" }} />}
                  </Stack>
                  <Stack direction="row">
                    <Typography variant="h6">Read Status : </Typography>
                    {testData.mountStatus === 'failed' ? <CloseIcon sx={{ color: 'red' }} /> : <DoneIcon sx={{ color: "green" }} />}
                  </Stack>
                  <Stack direction="row">
                    <Typography variant="h6">Write Status : </Typography>
                    {testData.mountStatus === 'failed' ? <CloseIcon sx={{ color: 'red' }} /> : <DoneIcon sx={{ color: "green" }} />}
                  </Stack>
                  <Button variant="contained" sx={{ color: '#ffff', m: '10px' }} onClick={close}>
                    Close
                  </Button>
                </Card>
              </Card>
            }
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default TestAccessModal;
