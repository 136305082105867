import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import SystemStatus from '../pages/system/system-status/SystemStatus';
import Analytics from '../pages/system/analytics/Analytics';
import StackedChart from '../pages/system/analytics/components/StackedChart';
import StackedChartModified from '../pages/system/analytics/components/StackedChartModified'
import TotalFileVolume from 'src/pages/system/analytics/components/TotalFileVolume';
import RootShareTable from 'src/pages/system/analytics/components/RootShareTable';
import DuplicateDataChart from 'src/pages/system/analytics/components/DuplicateDataChart';
import StorageGraph from 'src/pages/system/analytics/components/StorageGraph'
import LargestFolderByVolume from 'src/pages/system/analytics/components/LargestFolderByVolume';
import RepositoryReport from '../pages/system/repository-report/RepositoryReport';
import JobStatus from '../pages/system/job-status/JobStatus';
import FileExplorer from '../pages/system/file-explorer/FileExplorer';
import JobManagement from '../pages/configuration/job-management/JobManagement';
import PolicyManagement from '../pages/configuration/policy-management/PolicyManagement';
import ArchiveAgents from '../pages/configuration/archive-agents/ArchiveAgents';
import VolumeManagement from '../pages/configuration/volume-management/VolumeManagement';
import StubAgent from '../pages/configuration/stub-agent/StubAgent';
import DocumentClassifier from '../pages/configuration/document-classifier/DocumentClassifier';
import AccountPolicy from '../pages/user-groups/account-policy/AccountPolicy';
import UserManagement from '../pages/user-groups/user-management/UserManagement';
import LicenseDetails from '../pages/license/License';
import LdapConfiguration from '../pages/system-configuration/ldap-configuration/LdapConfiguration';
import BuildInfo from '../pages/system-configuration/build-info/BuildInfo';
import CustomBranding from '../pages/system-configuration/custom-branding/CustomBranding';
import GlobalConfiguration from '../pages/system-configuration/global-configuration/GlobalConfiguration';
import AddNewJob from 'src/pages/configuration/job-management/components/AddNewJob';
import AddFileStore from 'src/pages/configuration/volume-management/components/AddFileStore';
import AddDocumentCategory from 'src/pages/configuration/document-classifier/components/AddDocumentCategory';
import EditAgent from 'src/pages/configuration/archive-agents/components/EditAgent';
import EditFileStore from 'src/pages/configuration/volume-management/components/EditFileStore';
import EditDocumentCategory from 'src/pages/configuration/document-classifier/components/EditDocumentCategory';
import ManageProfile from 'src/pages/configuration/volume-management/components/ManageProfile';
import AddProfile from 'src/pages/configuration/volume-management/components/AddProfile';
import EditProfile from 'src/pages/configuration/volume-management/components/EditProfile';
import AddUser from 'src/pages/user-groups/user-management/components/AddUser';
import EditUser from 'src/pages/user-groups/user-management/components/EditUser';
import EditFtpUser from 'src/pages/user-groups/user-management/components/EditFtpUser';
import EditJob from 'src/pages/configuration/job-management/components/EditJob';
import AddPolicy from 'src/pages/configuration/policy-management/AddPolicy';
import EDiscovery from 'src/pages/system/e-discovery/EDiscovery';
import CompanyList from 'src/pages/configuration/company-list/CompanyList';
import AddCompany from 'src/pages/configuration/company-list/components/AddCompany';
import UserFeature from 'src/pages/system-configuration/user-feature/UserFeature';
import AuditLoginLog from 'src/pages/system/audit login log/AuditLoginLog';
import DataRetension from 'src/pages/system/data retension/DataRetension';
import AuditAccessLog from 'src/pages/system/audit access log/AuditAccessLog';
import ApplicationEventLog from 'src/pages/system/application-event-log/ApplicationEventLog';
import JobsStatus from 'src/pages/system/jobs-status/JobsStatus';
import { useSelector } from 'react-redux';
import AnalyticsPaths from 'src/pages/system/analytics-paths/AnalyticsPaths';
import PrivUserSearchHistory from 'src/pages/system/user-search-history/PrivUserSearchHistory';
import RolePermissions from 'src/pages/system-configuration/role-permissions/RolePermissions';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const companyMode = localStorage.getItem('enableSingleCompanyMode');
  const adminRole = useSelector((state) => state.login.adminRole);
  const [userRoutes, setUserRoutes] = useState([]);

  const allRoutes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'system-status', element: <SystemStatus /> },
        { path: 'analytics', element: <Analytics /> },
        { path: 'analytics/stackedchart', element: <StackedChart /> },
        { path: 'analytics/stackedchartmodified', element: <StackedChartModified /> },
        { path: 'analytics/totalfilevolume', element: <TotalFileVolume /> },
        { path: 'analytics/rootsharetable', element: <RootShareTable /> },
        { path: 'analytics/duplicatedatachart', element: <DuplicateDataChart /> },
        { path: 'analytics/storagegraph', element: <StorageGraph /> },
        { path: 'analytics/largestfolders', element: <LargestFolderByVolume /> },
        { path: 'repository-report', element: <RepositoryReport /> },
        { path: 'job-status', element: <JobStatus /> },
        { path: 'jobs-status', element: <JobsStatus /> },
        { path: 'file-explorer', element: <FileExplorer /> },
        { path: 'audit-login-log', element: <AuditLoginLog /> },
        { path: 'audit-access-log', element: <AuditAccessLog /> },
        { path: 'user-search-history', element: <PrivUserSearchHistory /> },
        { path: 'delete-codes', element: <DataRetension /> },
        { path: 'user-activity', element: <ApplicationEventLog /> },
        { path: 'analytics-restricted-by-path', element: <AnalyticsPaths /> },

        { path: 'job-list', element: <JobManagement /> },
        { path: 'create-job', element: <AddNewJob /> },
        { path: 'job-management/edit/:id', element: <AddNewJob /> },
        // { path: 'job-management/edit/:id', element: <EditJob /> },

        { path: 'policy-management', element: <PolicyManagement /> },
        { path: 'policy-management/add', element: <AddPolicy /> },
        { path: 'policy-management/edit/:id/:type', element: <AddPolicy /> },
        { path: 'policy-management/add/:id/:type', element: <AddPolicy /> },
        { path: 'document-classifier', element: <DocumentClassifier /> },
        { path: 'document-classifier/add', element: <AddDocumentCategory /> },
        { path: 'document-classifier/edit/:id', element: <AddDocumentCategory /> },
        // { path: 'document-classifier/edit/:id', element: <EditDocumentCategory /> },

        { path: 'agent-registration', element: <ArchiveAgents /> },
        { path: 'agent-registration/edit/:id', element: <EditAgent /> },

        { path: 'volume-management', element: <VolumeManagement /> },
        { path: 'volume-management/add', element: <AddFileStore /> },
        { path: 'volume-management/profile', element: <ManageProfile /> },
        { path: 'volume-management/profile/add/:type', element: <AddProfile /> },
        { path: 'volume-management/profile/edit/:id', element: <AddProfile /> },
        // { path: 'volume-management/profile/edit/:id', element: <EditProfile /> },
        { path: 'volume-management/edit/:id', element: <AddFileStore /> },
        // { path: 'volume-management/edit/:id', element: <EditFileStore /> },

        { path: 'volume-management', element: <VolumeManagement /> },
        { path: 'stub-agents', element: <StubAgent /> },

        { path: 'user-management', element: <UserManagement /> },
        { path: 'user-management/add', element: <AddUser /> },
        { path: 'user-management/edit/:id', element: <AddUser /> },
        // { path: 'user-management/edit/:id', element: <EditUser /> },
        // { path: 'user-management/edit/ftp/:id', element: <EditFtpUser /> },
        { path: 'user-management/edit/ftp/:ftpId', element: <AddUser /> },

        { path: 'account-policy', element: <AccountPolicy /> },
        { path: 'license-details', element: <LicenseDetails /> },
        { path: 'global-configuration', element: <GlobalConfiguration /> },
        { path: 'role-permissions', element: <RolePermissions /> },
        { path: 'custom-branding', element: <CustomBranding /> },
        { path: 'build-info', element: <BuildInfo /> },
        { path: 'ldap-configuration', element: <LdapConfiguration /> },

        { path: 'e-discovery', element: <EDiscovery /> },

        { path: 'company-list', element: <CompanyList /> },
        { path: 'company-management/add', element: <AddCompany /> },
        { path: 'company-management/edit/:id', element: <AddCompany /> },

        { path: 'user-feature', element: <UserFeature /> },
      ],
    },

    {
      path: 'configuration',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'edit-agent/:id', element: <EditAgent /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [{ element: <Navigate to="/auth/login" replace />, index: true }],
    },
    {
      path: '/:tag',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];
  const adminitratorRoutes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'system-status', element: <SystemStatus /> },
        { path: 'analytics', element: <Analytics /> },
        { path: 'analytics/stackedchart', element: <StackedChart /> },
        { path: 'analytics/stackedchartmodified', element: <StackedChartModified /> },
        { path: 'analytics/totalfilevolume', element: <TotalFileVolume /> },
        { path: 'analytics/rootsharetable', element: <RootShareTable /> },
        { path: 'analytics/duplicatedatachart', element: <DuplicateDataChart /> },
        { path: 'analytics/storagegraph', element: <StorageGraph /> },
        { path: 'analytics/largestfolders', element: <LargestFolderByVolume /> },
        { path: 'repository-report', element: <RepositoryReport /> },
        { path: 'job-status', element: <JobStatus /> },
        // { path: 'jobs-status', element: <JobsStatus /> },
        { path: 'file-explorer', element: <FileExplorer /> },
        // { path: 'audit-login-log', element: <AuditLoginLog /> },
        // { path: 'audit-access-log', element: <AuditAccessLog /> },
        // { path: 'delete-codes', element: <DataRetension /> },
        { path: 'user-activity', element: <ApplicationEventLog /> },

        { path: 'job-list', element: <JobManagement /> },
        { path: 'create-job', element: <AddNewJob /> },
        { path: 'job-management/edit/:id', element: <AddNewJob /> },
        // { path: 'job-management/edit/:id', element: <EditJob /> },

        { path: 'policy-management', element: <PolicyManagement /> },
        { path: 'policy-management/add', element: <AddPolicy /> },
        { path: 'policy-management/edit/:id/:type', element: <AddPolicy /> },
        { path: 'policy-management/add/:id/:type', element: <AddPolicy /> },
        { path: 'document-classifier', element: <DocumentClassifier /> },
        { path: 'document-classifier/add', element: <AddDocumentCategory /> },
        { path: 'document-classifier/edit/:id', element: <AddDocumentCategory /> },
        // { path: 'document-classifier/edit/:id', element: <EditDocumentCategory /> },

        { path: 'agent-registration', element: <ArchiveAgents /> },
        { path: 'agent-registration/edit/:id', element: <EditAgent /> },

        { path: 'volume-management', element: <VolumeManagement /> },
        { path: 'volume-management/add', element: <AddFileStore /> },
        { path: 'volume-management/profile', element: <ManageProfile /> },
        { path: 'volume-management/profile/add/:type', element: <AddProfile /> },
        { path: 'volume-management/profile/edit/:id', element: <AddProfile /> },
        // { path: 'volume-management/profile/edit/:id', element: <EditProfile /> },
        { path: 'volume-management/edit/:id', element: <AddFileStore /> },
        // { path: 'volume-management/edit/:id', element: <EditFileStore /> },

        { path: 'volume-management', element: <VolumeManagement /> },
        { path: 'stub-agents', element: <StubAgent /> },

        { path: 'user-management', element: <UserManagement /> },
        { path: 'user-management/add', element: <AddUser /> },
        { path: 'user-management/edit/:id', element: <AddUser /> },
        // { path: 'user-management/edit/:id', element: <EditUser /> },
        // { path: 'user-management/edit/ftp/:id', element: <EditFtpUser /> },
        { path: 'user-management/edit/ftp/:ftpId', element: <AddUser /> },

        { path: 'account-policy', element: <AccountPolicy /> },
        { path: 'license-details', element: <LicenseDetails /> },
        { path: 'global-configuration', element: <GlobalConfiguration /> },
        { path: 'role-permissions', element: <RolePermissions /> },
        { path: 'custom-branding', element: <CustomBranding /> },
        { path: 'build-info', element: <BuildInfo /> },
        { path: 'ldap-configuration', element: <LdapConfiguration /> },

        // { path: 'e-discovery', element: <EDiscovery /> },

        // { path: 'company-list', element: <CompanyList /> },
        // { path: 'company-management/add', element: <AddCompany /> },
        // { path: 'company-management/edit/:id', element: <AddCompany /> },

        // { path: 'user-feature', element: <UserFeature /> },
      ],
    },

    {
      path: 'configuration',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'edit-agent/:id', element: <EditAgent /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [{ element: <Navigate to="/auth/login" replace />, index: true }],
    },
    {
      path: '/:tag',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];
  const privilegedRoutes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='e-discovery' replace />, index: true },
        { path: 'file-explorer', element: <FileExplorer /> },
        { path: 'e-discovery', element: <EDiscovery /> },
        { path: 'analytics-restricted-by-path', element: <AnalyticsPaths /> },
      ],
    },

    {
      path: 'configuration',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'edit-agent/:id', element: <EditAgent /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [{ element: <Navigate to="/auth/login" replace />, index: true }],
    },
    {
      path: '/:tag',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];
  const ldapRoutes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='e-discovery' replace />, index: true },
        { path: 'file-explorer', element: <FileExplorer /> },
        { path: 'e-discovery', element: <EDiscovery /> },
        { path: 'analytics-restricted-by-path', element: <AnalyticsPaths /> },
      ],
    },

    {
      path: 'configuration',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'edit-agent/:id', element: <EditAgent /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [{ element: <Navigate to="/auth/login" replace />, index: true }],
    },
    {
      path: '/:tag',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];
  const superAdminRoutes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'system-status', element: <SystemStatus /> },
        { path: 'analytics', element: <Analytics /> },
        { path: 'analytics/stackedchart', element: <StackedChart /> },
        { path: 'analytics/stackedchartmodified', element: <StackedChartModified /> },
        { path: 'analytics/totalfilevolume', element: <TotalFileVolume /> },
        { path: 'analytics/rootsharetable', element: <RootShareTable /> },
        { path: 'analytics/duplicatedatachart', element: <DuplicateDataChart /> },
        { path: 'analytics/storagegraph', element: <StorageGraph /> },
        { path: 'analytics/largestfolders', element: <LargestFolderByVolume /> },
        { path: 'repository-report', element: <RepositoryReport /> },
        { path: 'job-status', element: <JobStatus /> },
        { path: 'jobs-status', element: <JobsStatus /> },
        // { path: 'file-explorer', element: <FileExplorer /> },
        // { path: 'audit-login-log', element: <AuditLoginLog /> },
        // { path: 'audit-access-log', element: <AuditAccessLog /> },
        // { path: 'delete-codes', element: <DataRetension /> },
        { path: 'user-activity', element: <ApplicationEventLog /> },

        { path: 'job-list', element: <JobManagement /> },
        { path: 'create-job', element: <AddNewJob /> },
        { path: 'job-management/edit/:id', element: <AddNewJob /> },
        // { path: 'job-management/edit/:id', element: <EditJob /> },

        { path: 'policy-management', element: <PolicyManagement /> },
        { path: 'policy-management/add', element: <AddPolicy /> },
        { path: 'policy-management/edit/:id/:type', element: <AddPolicy /> },
        { path: 'policy-management/add/:id/:type', element: <AddPolicy /> },
        { path: 'document-classifier', element: <DocumentClassifier /> },
        { path: 'document-classifier/add', element: <AddDocumentCategory /> },
        { path: 'document-classifier/edit/:id', element: <AddDocumentCategory /> },
        // { path: 'document-classifier/edit/:id', element: <EditDocumentCategory /> },

        { path: 'agent-registration', element: <ArchiveAgents /> },
        { path: 'agent-registration/edit/:id', element: <EditAgent /> },

        { path: 'volume-management', element: <VolumeManagement /> },
        { path: 'volume-management/add', element: <AddFileStore /> },
        { path: 'volume-management/profile', element: <ManageProfile /> },
        { path: 'volume-management/profile/add/:type', element: <AddProfile /> },
        { path: 'volume-management/profile/edit/:id', element: <AddProfile /> },
        // { path: 'volume-management/profile/edit/:id', element: <EditProfile /> },
        { path: 'volume-management/edit/:id', element: <AddFileStore /> },
        // { path: 'volume-management/edit/:id', element: <EditFileStore /> },

        { path: 'volume-management', element: <VolumeManagement /> },
        { path: 'stub-agents', element: <StubAgent /> },

        { path: 'user-management', element: <UserManagement /> },
        { path: 'user-management/add', element: <AddUser /> },
        { path: 'user-management/edit/:id', element: <AddUser /> },
        // { path: 'user-management/edit/:id', element: <EditUser /> },
        // { path: 'user-management/edit/ftp/:id', element: <EditFtpUser /> },
        { path: 'user-management/edit/ftp/:ftpId', element: <AddUser /> },

        { path: 'account-policy', element: <AccountPolicy /> },
        { path: 'license-details', element: <LicenseDetails /> },
        { path: 'global-configuration', element: <GlobalConfiguration /> },
        { path: 'custom-branding', element: <CustomBranding /> },
        { path: 'build-info', element: <BuildInfo /> },
        { path: 'ldap-configuration', element: <LdapConfiguration /> },

        // { path: 'e-discovery', element: <EDiscovery /> },

        { path: 'company-list', element: <CompanyList /> },
        { path: 'company-management/add', element: <AddCompany /> },
        { path: 'company-management/edit/:id', element: <AddCompany /> },

        { path: 'user-feature', element: <UserFeature /> },
      ],
    },

    {
      path: 'configuration',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'edit-agent/:id', element: <EditAgent /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [{ element: <Navigate to="/auth/login" replace />, index: true }],
    },
    {
      path: '/:tag',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];
  const dataGuardianRoutes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='audit-access-log' replace />, index: true },
        { path: 'audit-login-log', element: <AuditLoginLog /> },
        { path: 'user-search-history', element: <PrivUserSearchHistory /> },
        { path: 'audit-access-log', element: <AuditAccessLog /> },
        { path: 'delete-codes', element: <DataRetension /> },
        { path: 'user-activity', element: <ApplicationEventLog /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [{ element: <Navigate to="/auth/login" replace />, index: true }],
    },
    {
      path: '/:tag',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: '/auth/login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  useEffect(() => {
    switch (adminRole) {
      case 'ADMINISTRATOR':
        setUserRoutes(adminitratorRoutes);
        break;
      case 'PRIVILEGED':
        setUserRoutes(privilegedRoutes);
        break;
      case 'SUPERADMIN':
        setUserRoutes(superAdminRoutes);
        break;
      case 'DATAGUARDIAN':
        setUserRoutes(dataGuardianRoutes);
        break;
      case 'LDAP':
        setUserRoutes(ldapRoutes);
        break;
      default:
        setUserRoutes(allRoutes);
        break;
    }
  }, [adminRole]);

  return useRoutes(userRoutes);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard

// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
