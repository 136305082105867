import { GetDetails, postInfo } from "src/utils/fetchServices";
import { SUCCESS_ALERT ,PROGRESS_START,PROGRESS_DONE, ERROR_ALERT} from 'src/redux/reducers/alertReducer';
export const LDAP_FETCH_SUCCESS = 'LDAP_FETCH_SUCCESS';
export const AZURE_FETCH_SUCCESS = 'AZURE_FETCH_SUCCESS';
export const POST_SUCCESS = 'POST_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';

export const getLdapConfiguration = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `system-codes/ldap-configuration/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: LDAP_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getAzureConfiguration = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `azure-ad-config/company/${companyId}` });
    const data = response.data;
    if(response.status == 200){
      dispatch({
        type: AZURE_FETCH_SUCCESS,
        payload: data,
      });
    }else{
      dispatch({
        type: AZURE_FETCH_SUCCESS,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const postLdap = (body, url) => async (dispatch) => {
  // dispatch({type: DATA_LOADER});
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const companyId = localStorage.getItem('companyId');
    const response = await postInfo({ 
      url: url || `system-codes/ldap-configuration/company/${companyId}`,
      body,
    }, 
    dispatch);
    if(response && response.status === 200){
      const data = response.data;
      dispatch({
        type: POST_SUCCESS,
        payload: data,
      });
      dispatch({
        type: LDAP_FETCH_SUCCESS,
        payload: data,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: url ? 'LDAP tested Successfully' : 'LDAP Setting Updated Successfully',
      });
    } else{
      dispatch({type: FETCH_FAIL});
    }

  } catch (error) {
    dispatch({type: FETCH_FAIL});
  };
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const updateAzureConfiguration = (body) => async (dispatch) => {
  // dispatch({type: DATA_LOADER});
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo({ 
      url: `azure-ad-config/`,
      body,
      method: "PUT"
    }, 
    dispatch);
    if(response && response.status === 200){
      dispatch({type: FETCH_FAIL}); // for loading false
      dispatch({
        type: SUCCESS_ALERT,
        payload: "Azure Setting Updated Successfully",
      });
    } else{
      dispatch({type: FETCH_FAIL});
    }

  } catch (error) {
    dispatch({type: FETCH_FAIL});
  };
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const deleteAzureConfiguration = (body) => async (dispatch) => {
  dispatch({type: DATA_LOADER});
  try {
    const response = await postInfo({ 
      url: `azure-ad-config/clientId/`,
      body: {},
      method: "DELETE"
    }, 
    dispatch);
    if(response && response.status === 200){
      dispatch({type: FETCH_FAIL}); // for loading false
      dispatch({
        type: SUCCESS_ALERT,
        payload: "Azure Setting Deleted Successfully",
      });
    } else{
      dispatch({type: FETCH_FAIL});
    }

  } catch (error) {
    dispatch({type: FETCH_FAIL});
  };
};



